.phrase-text-container {
   max-height:80vh;
   min-height:60vh;
   width:100%;
   //background-color: $site-background-color;

   .phrase-text {
      display:flex;
      padding-top: 5px !important;
      padding-left: 15px !important;
      padding-right: 10px !important;
      padding-bottom: 0px !important;
      background-color: $site-background-color;

      .phrase-text-checkbox {
         width:30px;
         padding-top:5px;
         height:30px;
      }



      .phrase-text-content{
         flex:10;

         textarea {
            min-width:100%;
            border:1px solid #ddd;
            //resize:none;
            font-size:1rem;
            overflow-y:auto;
            padding-left:5px;
            padding-right:5px;
            padding-top:3px;
            padding-bottom:3px;
            cursor:pointer;
         }

         .phrase-text-system {
            background-color:#efefef;
         }

         .phrase-text-focus {
            border:1px solid red;
         }
      }

      .phrase-auto-text-content{
         position:relative;
         flex:11;

        textarea {
            min-width:104%;
            font-size:1rem;
            overflow-y:auto;
            padding-left:5px;
            padding-right:5px;
            padding-top:3px;
            padding-bottom:3px;
         }
      }

      .phrase-text-icon {
         width:40px;
         height:30px;
         cursor:pointer;
         font-size:1.4rem;
         padding-top:5px;
         padding-left: 15px;
         text-align:center;
      }
   }

   .phrase-text-no-phrases{
      text-align:center;
      font-size:2rem;
      padding-top:20%;
      color: $site-main-color;
      font-family: $title-font;
   }


}

.phrase-add-button-container{
   justify-content: end;
   top:20px;
}

.phrase-auto-text-copy-btn{
   padding-top:0.5rem;
   padding-bottom:0.5rem;
   width: 10rem;
}

.phrase-input-line{
   display: grid;
   grid-template-columns: 0.5fr 2fr 1fr;
   gap: 1rem;
   background-color: #c2d7e8;
   padding: 0.5rem 1rem 0.5rem 1rem;
   align-items: center;
   font-family: $title-font;
}

.dialog-buttons{
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #c2d7e8; 
   gap: 0.5rem;
}