.pictures-frame {
  margin-top:10px;
  width:58vw;
}

.pictures-frame-small {
  position: relative;
  margin-top:10px;
  width:70vw;
  display:flex;
  flex-wrap: wrap;
  .picture-box {
    flex:1;
    width:24vw;
  }
  .bk-module-section:nth-child(odd) {
    padding-left:10px !important;
    padding-right:0;
    margin-right:calc(4vw - 20px);
  }
  .bk-module-section:nth-child(even) {
    padding-right:10px !important;
    padding-left:0;
  }
}

.picture-module-picture-large{
  min-height:24vw;
  max-height:42vw;
  width:56vw;
}

.picture-module-picture-1-col{
  height: 28.5vw;
  max-height:600px;
  padding-bottom: 10px;
}

.picture-module-picture-2-cols{
  height: 23.5vw;
  max-height:400px;
  padding-bottom: 10px;
}

.picture-module-picture-3-cols{
  height: 15vw;
  max-height:300px;
  padding-bottom: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}
