.calculation-wrapper{
  margin-left:50px;
  margin-right: 70px;
  margin-bottom:60px;
}


.calculation-edit-comment-old {
  font-size: 12px;
  margin-left: 40px;
  display: inline-block;
  margin-top: 4px;
}


.calculation-edit-comment{
  margin-left: 40px;
  display: inline-block;
  margin-top: 4px;
}


.calculation-edit-comment-input {
  font-size: 16px;
  //float: right;
  width: 300px;
  margin-top: 11px;
  //margin-bottom: 20px;
  margin-left: 10px;
  height: 30px;
}

.calculation-print-icon {
  float: right;
  margin-right: 30px;
  margin-top: -50px;
}


.calculation-print-icon {
  font-size: 24px !important;
  margin-top:10px;
}
.calculation-print-image {
  width: 32px;
  cursor: pointer;
}

.calculation-header {
  float: left;
  color: black;
  font-size: 30px;
  font-family: Arial;
  margin-left: 46px;
  margin-top: 10px;
}

.calculation-table-container {
  margin-top: 10px;
  display:flex;
  flex-wrap: wrap;
}

.calculation-result-wrapper {
  width: 100%;
  flex:1;
  margin-left:2rem;
  margin-right:30px;
  min-width:700px;
  //margin-top:20px;
}

.calculation-sub-header-left {
  margin-left: 15px;
  font-size: 18px;
  margin-top: 60px;
  height: 40px;
}





.area-table-name {
  font-size: 22px;
  padding:4px;
  // display: inline-block;
  // vertical-align: top;
  // margin-top: 5px;
}

.area-table-button {
  text-align:right;
}

.area-table-heading {
  margin-top: 0;
}

.area-table {
  margin-top: 40px;
  margin-left: 4rem;
 // margin-right: 16px;
 // margin-bottom: 40px;
}

.area-table-border-top {
  border-top: 1px solid #ccc;
}

.area-table-border-left {
  border-left: 1px solid #ccc;
}

.area-table-border-right {
  padding: 4px;
  text-align: center;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.area-table-header {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: #f5f5f5;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.area-table-delete-btn {
  background-color: transparent;

}

.area-addbtn-row {
  justify-content: start;
}

.delete-addon-button {
  text-align: center;
  color: red;
  margin-top: 12px;
  cursor: pointer;

  &:hover {
    color: $hover-color;
  }
}

.area-table-header-center {
  text-align: center;
  padding: 4px;
}

.area-table-header-align-right {
  text-align: right;
  padding: 4px;
}

.area-table-header-center-area {
  text-align: right;
  padding: 4px;
}

.area-table-right-column{
  text-align:right;
  padding:4px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: #f5f5f5;
  font-weight: bold;
  white-space: nowrap;

}

.area-table-right-column > div {
  margin-right:20px;
  margin-top:1px;
  padding-top:6px;
  padding-bottom:6px;
}

.area-table-header-content {
  margin-left: 3px;
}

.area-table-col {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 4px;
  text-align: center;
  background-color: white;
  // margin-top:4px;
}

.area-table-col > div {
  margin-top:4px;
}

.area-table-col-input {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 4px;
  background-color: white;
}

.area-table-button-green {
  width: 160px;
  text-align: center;
  color: $element-color;
  background-color: $site-main-color;
  font-size: 16px;
  border: none !important;
  font-family: $title-font !important;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer; 


&:hover {
  background-color: $hover-color;
  color: $element-color;
}
}

.area-table-header-input-container {
 //
}


.area-table-header-input {
  width: 100%;
  text-align: right;
  padding-right: 45px !important;
  box-shadow: none !important;
}

#area-table-header-input-no-border{
  border: none !important;
}

.area-table-header-input-icon-right > i {
  right: 19px;
}

.area-table-select {
  width: 100%;
  // padding: 0;
  margin: 0;
  border: none !important;
  box-shadow: none !important;
  // font-size: 16px;
}



.inline-block {
  display: inline-block;
}



.input-icon {
  position: relative;

}

  .input-icon > i {
      position: absolute;
      display: block;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      top: 50%;
      pointer-events: none;
      width: 25px;
      text-align: center;
      font-style: normal;
  }

  .input-icon > input {
      padding-left: 25px;
      padding-right: 0;
  }

.input-icon-right > i {
  right: 19px;
}

.input-icon-right > input {
  padding:0;
  width: 100%;
  text-align: right;
  padding-right: 45px !important;
  box-shadow: none !important;
}

.input-icon-small-right > i {
  /* right: 0;*/
  left: 110px; /* Because the inputs are 280px long*/
}

.input-icon-small-right > input {
  padding-left: 0;
  padding-right: 30px;
  text-align: right;
}



.calculation-frame {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding:10px;
}

.calculation-frame-sum {
  background-color: $site-background-color;
  border: 1px solid #ccc;
  padding:10px;
  padding-left: 70px;
  margin-top: 20px;
}



.calculation-table {
  margin-top: 10px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 40px;
}

.calculation-row {
  margin-top:8px;
  margin-bottom:8px;
  font-size:16px;
}

.calculation-cell {
  background-color: transparent;
  font-size:16px;
  
}

.calculation-auto-textbox{
  border-radius:4px !important;
  margin-top:1px;
}

.calculation-input-frame {
  text-align:right;
  padding-right:10px;
  width: 300px !important;
}


.input-icon > input {
  font-size:16px;
  margin-top:1px;
}

.input-icon > textarea {
  font-size:16px;
}

.input-icon > select {
  font-size:16px;
}

.input-icon > i {
  font-size:16px;
  //margin-top:-2px;
}

.calculation-note-row {
  width:100%;
}

.calculation-total-cell{
  font-size:18px;
}

.calculation-sum-right {
  text-align:right;
   padding-right:20px;
}


.value-reduction-button-green {
  width: 160px;
  text-align: center;
  color: $element-color;
  background-color: $site-main-color;
  font-size: 16px;
  border: none !important;
  font-family: $title-font !important;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer; 
}

.value-reduction-button-green:hover {
  background-color: $hover-color;
  color: $element-color;
}


.calculation-required-error{
  border-color: red !important;
}












.property-frame {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding:10px;
  margin-bottom:10px;
}




.property-group-header {
  font-weight: bold;
}

.property-group-items-container{
  display:flex;
  margin-top:8px;
}

.property-itemgroup-name{
  flex:3;
}

.property-itemgroup-item{
  flex:6;
 

}

.property-itemgroup-helptext {
  flex:1;

  img {
    margin-top:10px;
  }
}

.property-item-container{
  display: flex;
  margin-top:4px;
}

.property-item-value{
  flex:5;
  position: relative;
  margin-right:20px;

  input {
    width: 138px;
    padding: 6px 6px; 
    text-align: right;
    font-size: 16px;
  }

  i {
    left: 138px; 
    position: absolute;
    top: 15%;
    width: 25px;
    text-align: center;
    font-style: normal;
  }
}

.property-item-select{
  flex:7;
  position: relative;

  select {
    width: 200px;
    padding: 6px 6px; 
    text-align: left;
    font-size:16px;
    margin-right:5px;
  }

  img {
    display: inline-block;
    height: 18px;
    cursor: pointer;
    margin-top: 6px;
    margin-left:2px;
    filter: opacity(.5) drop-shadow(0 0 0 #ff0000);
  }
}

.property-item-text-area{
  flex:6;

  textarea {
    width:100%;
    padding: 6px 6px 6px 10px;
    font-size:16px;
  }
}

.property-item-value-area{
  flex:3;
  text-align:right;
  margin-left: 20px;

  input {
    width: 138px;
    padding: 6px 6px; 
    text-align: right;
    font-size:16px;
  }
}

.calculation-index-warning {
  border:1px solid red;
  background-color: lightpink;
  border-radius:4px;
  padding:10px 10px;
}