.upload-dialog-frame{
   max-height:60vh;
   max-width:60vw;
   display:flex;
   text-align:center;
   overflow:hidden;
   height: 100%;
   width:100%;
}

.upload-dialog-help-text{
   color:rgba(106,157,158,1);
}

.upload-dialog-image-container{
   display: table;
   height: 100%;
   width:100%;


   .upload-dialog-image{
      text-align:center;
      //max-height:45vh;
     // object-fit: contain;

     img{
         //height:100%;
         //width: 100%;
         display: block;
         transform-origin: top left;
         white-space: nowrap;
         border:1px solid #777;
         max-height:60vh;
         object-fit: contain;
       }
   }  
}

.upload-button-frame{
   flex:1;
}

.upload-dialog-border {
   flex:4;
   min-height:20vh;
   // margin-left:2vw;
   // margin-right:2vw;
   border:4px dashed rgba(106,157,158,1);
   border-radius: 10px;
   cursor: pointer;
   text-align:center;

   .frame-header {
      font-size: 18px;
      font-weight: bold;
      margin-top:3vw;
      color: rgba(106,157,158,1);
    }

    .frame-text {
      font-size: 12px;
      color:black;
    }
}

.upload-buttons {
   margin-left: 20px;
   margin-right: 20px;
   padding-top: 0 !important;
}

.upload-header{
white-space: nowrap;
}
.upload-header-row{
   display: flex;
   flex-direction: row;
   gap: 20px;
   align-items: center;
}

.upload-buttons > div:first-child {
   margin-right: auto;
 }

 .upload-dialog-rotate-icon {
    float:right;
 }

 .upload-dialog-transform-buttons{
   float:right;
   margin-bottom: 8px;
 }

 .upload-dialog-camera-icon{
    float:right;
    color:$site-main-color;
    cursor:pointer;
 }