@import 'src/assets/style/scss/base/variables.scss';
  
.bkbutton-small {
  min-width: 80px;
  height: 25px;
  text-align: center;
  font-size: 14px;
  color: white;
  background-color: #7DA868;
  border-radius: 5px;
  font-family: $tech-font;
  // margin-right: 10px;
  // margin-left: 10px;
  //margin-top: px;
  padding-top: 2px;
  padding-left:10px;
  padding-right:10px;
  cursor: pointer;

  &:hover {
    background-color:#294856;
    color:white;
  }
}

.bkbutton-disabled {
  background-color: $site-grey-color !important;
  cursor: auto !important;
}