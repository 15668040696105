// $bk-background:linear-gradient(90deg, rgba(106,157,158,1) 0, rgba(135,176,177,1) 50%, rgba(212,228,228,1) 100%);
// $bk-main-color:rgba(106,157,158,1);
// $bk-header-color:#354753;

// $bk-light-blue:#c2d6e5;;
// $bk-medium-blue:#99b7cc;

// $bk-light-green:#d5e5e7;
// $bk-medium-green:#a6cbc9;



// Page wrappers
.bk-container {
   flex: auto;
   width:100%;
   background: $bk-background;
   padding-bottom:100px;


   .form-control {
      border-radius:2px !important;
      border-color:rgb(118,118,118) !important;
      box-shadow:none !important;
      padding-top:2px !important;
      padding-bottom: 2px !important;
      line-height: normal !important;
      font-family:Arial;
   }

   #form-control-thin-input{
      line-height: 10px !important;
   }

   input:focus {
      outline: none !important;
      border-color: black;
      box-shadow: 0 0 0 1px black;
   }
   

}

input:read-only{
   background-color: $site-grey-light-color !important;
   color: #6c757d !important;
 }
 
 input:read-only:focus{
   background-color: $site-grey-light-color !important;
   color: #6c757d !important;
   box-shadow: none !important;
   outline: none !important;
 }

 textarea:read-only{
   background-color: $site-grey-light-color !important;
   color: #6c757d !important;
 }
 
 textarea:read-only:focus{
   background-color: $site-grey-light-color !important;
   color: #6c757d !important;
   box-shadow: none !important;
   outline: none !important;
 }
 
.MuiIconButton-root:read-only:hover{
   background-color: transparent !important;
 }

.bk-container-blank {
   flex: auto;
   width:100%;
}

.btn_grid{
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   column-gap: 3vh;
   row-gap: 3vh;
}

input[type="radio"] {
   appearance: none;
 
   width: 1rem;
   height: 1rem;
   cursor: pointer;
   border: 0.1rem solid #6d6d68;
   border-radius: 50%;
   background-color: #fff;
 }

input[type="radio"]:checked {
   background-color: $site-main-color;
 }

 input[type="radio"]:focus {
   outline: none !important;
   border-color: transparent !important;
 }

input[type="checkbox"] {
   width: 1rem;
   height: 1rem;
   cursor: pointer;
   border: 0.1rem solid #6d6d68;
   background-color: #fff;
}

 input[type="checkbox"]:focus {
   outline: none !important;
   border-color: transparent !important;
 }

.bk-report-header{
   display: grid;
   grid-template-columns: 2fr auto auto;
   gap: 1rem;
   align-items: center;
   color: $site-main-color;
   font-size: $text-l;
   font-family: $title-font;
   margin-bottom: 1rem;
}

.bk-info-header{
   background-color: #f1c794;
   color: $site-black-color;
   font-size: $text-xs;
   font-family: $tech-font;
   margin: -0.5rem 0 1rem 0;
   padding: 0.25rem 1rem 0.25rem 1rem;
   border-radius: 5px;
}

.bk-row-header {
   color: $site-main-color;
   font-size: $text-l;
   //text-align: left;
 }
 
 .bk-row-header-title {
   font-family: $title-font;
 }


.bk-page-frame {
   width:1200px;
   min-height:800px;
   margin-left:auto;
   margin-right: auto;
   margin-top:30px;
   padding:10px;
   background-color: white;
}

.module-row {
   padding:0;
   display: flex;
   margin-bottom:10px;
   
   .module-row-heading {
      text-align:right;
      padding-right:10px;
   }

   .module-row-input{
      min-width: auto;
      width:calc(100% - 10px);
   }

}

.selected{
   outline: solid 2px $site-main-color;
}

.bk-page-section {
   margin-left:10px;
   margin-top:20px;
   margin-right: 20px;

   .description {
      flex:15;
   }

   .action-menu {
      flex:1;
      margin-left:10px;
      color: $bk-main-color;
      cursor: pointer;
   }

   .bk-paragraph {
      margin-top:20px;

      .bk-paragraph-header {
         font-weight:bold;
         font-size:1.2rem;
      }

      .bk-paragraph-content {
         margin-left:20px;
      }
   } 

   .bk-section {
      margin-left:2rem;
      margin-bottom:4rem;
      margin-right: 2rem;
      
      .header {
         font-size: 1.2rem;
         font-weight:bold;
      }
   
      .text {
         font-size: 1rem;
         margin-left:1rem;
         margin-top:4px;
      }
   }
}

.bk-section-header {
   font-family:arial;
   font-size: $text-md;
   background-color: $site-background-color ;
   color:$site-main-color;
   font-family: $title-font;
   padding-left:10px;
   margin-bottom:10px;
   display:flex;
   border-radius: 10px;
 
   .description {
     flex:15;
   }
   .action-menu {
     flex:1;
     margin-left:10px;
     color: rgba(106,157,158,1);
     cursor: pointer;
   }
   .header-icon {
      margin-top:7px;
      margin-right:5px;
      min-width:20px;
   }
 }

 .bk-instruction-text{
    background-color: #ddd;
    user-select:none;
    border:1px solid #aaa;
    border-radius:2px;
    margin-right:3px;
    margin-left:1px;
    margin-top: 30px;
    
    div {
       padding:10px;
    }
 }

 .cursor-pointer{
    cursor: pointer;
 }


.bk-module-section {
   padding-left:5px;
   padding-right:5px;
   margin-bottom:30px;



   .text {
      min-width:calc(100% - 20px);
      margin-left:10px;
      margin-right:10px;
      font-size: 1.2rem;
   }

   .content-frame{
      padding: 10px;
      border-width: 5px !important;
      border-top-width: 0 !important;
      border-color: #E6E7E8 !important;
      border-style: solid !important;
      border-radius: 0 0 5px 5px !important;
   }

   .module-row {
      padding:0;
      display: flex;
      margin-bottom:10px;
      
      .module-row-heading {
         text-align:right;
         padding-right:10px;
      }

      .module-row-input{
         min-width: auto;
         width:calc(100% - 10px);
      }
   
   }

   
   
   .module-row-caption {
      font-size: 0.8em;
      padding:0;
      display: flex;

      .module-row-heading {
         text-align:right;
         padding-right:10px;
      }
   }

}

.bk-display-none{
   display: none !important;
}

.bk-text-blue {
   color: $site-main-color;
   font-family: $base-font;
   font-size: $text-sm
}

.bk-text-white {
   color: $element-color;
   font-family: $base-font;
   font-size: $text-sm
}





/***********************************/
// Old stuff

.bk-calculation-wrapper{
   width:1200px;
   min-height:800px;
   margin-left:auto;
   margin-right: auto;
   margin-top:30px;
   margin-bottom:60px;
   padding:10px;
   background-color: white;
 }

 .bk-calculation-header {
   color: #44758C;
   font-family: $title-font !important;
   background: $bk-background;
   font-size: 1.9rem;
   padding-left:20px;
   font-family: Arial;
   padding-top:2px;
   padding-bottom:2px;
 }

 .bk-calculation-project-info{
   font-size: 0.7rem;
   padding-left:20px;
   padding-top:4px;
   font-family: Arial;
   cursor:pointer;
 }
 

.bk-calculation-section {
   margin-left:10px;
   margin-top:20px;

   .description {
      flex:15;
   }

   .action-menu {
      flex:1;
      margin-left:10px;
      color: $bk-main-color;
      cursor: pointer;
      }

   .bk-paragraph {
      margin-top:20px;
      margin-left:20px;

      .bk-paragraph-header {
         font-weight:bold;
         font-size:1.2rem;
      }

      .bk-paragraph-content {
         margin-left:20px;
      }
   } 
}

/*.bk-header {
   font-size: 1.2rem;
   color:white;
   background: $bk-header-color;
   padding-left:10px;
   margin-bottom:20px;
   display:flex;
   height:33px;
   padding-top:4px;

   .description {
      flex:15;
    }

   .action-menu {
      flex:1;
      margin-left:10px;
      padding-top: 2px;
      cursor:pointer;
    }
}*/

.bk-header-icon {
   color: #a9a9a9;
}

.bk-small-header{
   font-family: $report-font;
   font-size: $text-sm;
}

.bk-icon-printer{
   float: right;
   width: 2.5vw;
   cursor:pointer;
   margin-right:40px;
   margin-top:4px;
}



.bk-edit-comment{
   margin-top: 10px;
   font-size: $text-sm;
   color: $site-main-color;
 }

 .bk-edit-comment-input {
   margin-top: 8px;
   font-size: 1rem;
   width: 20vw;
   box-shadow: none !important;
 }

 .text-line-frame {
   display:flex;
   align-items: center;
   width:100%;
   
   .text-box {
      width:calc(100% - 20px);
   }

 }

 #text-line-max{
   width:100% !important;
 }

 .auto-text-box-frame {
   display:flex;
   align-items: center;
   width:100%;
   
   .text-box {
      
      width:calc(100% - 30px);

      textarea {
         min-width:100%;
         font-size: 1rem;
         padding:6px;
         resize: vertical;
         border-radius:0;
         box-shadow: none;
      }

      textarea:focus {
         outline: none !important;
         border-color: black ;
         box-shadow: 0 0 0 1px black ;
      }
   }

   .phrase-icon {
      vertical-align:top;
    
      width:40px;
      padding-left:16px;
      font-size:1.6rem;
      cursor:pointer;
   }

   .add-icon {
      vertical-align:top;
     
      width:40px;
      padding-left:16px;
      font-size:1rem;
      cursor:pointer;
   }
 }

 .change-color{
   label.Mui-focused {
      color: '#7DA868' !important
   }

   .MuiInput-underline:after {
      border-bottom-color: '#7DA868' !important
   }
 }

 .on-focus{
   label.Mui-focused {
      color: '#7DA868' !important
   }
 }