﻿/*.navbar {
  flex:none;
  width: 100%;
  height: 70px;
  //background-color: $color-nav-bar;
  padding-left: 7rem;
  padding-right: 3rem;
  background-color: rgba(106,157,158,1) ;

  @include reduceNavBarPadding {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @include removeNavBarPadding {
    padding-left: 1rem;
    padding-right: 1rem;
  }*/


  .navbar-color {
    background-color: $element-color !important;
  }

  .navbar-offcanvas {
    background-color: $site-main-color !important;
  }

  .btn-close {
    color: $site-background-color !important;
  }

  .btn-burger {
    color: $site-main-color !important;
  }

  .navbar-droptown-text {
    opacity:1 !important;
    color:$site-main-color !important;
    font-weight:bold !important;
    opacity: 0.7 !important;
    justify-content: center !important;
    font-family: $title-font !important;
  }

  .navbar-droptown-text-name {
    font-family: $title-font !important;
    opacity: 0.7 !important;
    color: $site-main-color !important;
    font-weight:bold !important;
  }

  #navbar-drop-btn {
    opacity: 1 !important;
  }

  .navbar-local-btn {
      font-size: $text-sm !important;
      color: $site-main-color !important;
      font-family: $tech-font !important;
    
      &:hover {
        color:$hover-color !important;
        background-color: transparent !important;
      }

      &:active{
        text-decoration: underline;
        background-color: transparent !important;
      }

      &:focus{
        text-decoration: underline;
        background-color: transparent !important;
      }


    
    }
 /* .navbar-logo_placeholder {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }

  .navbar-wrapper {
    width: 100%;
    display: flex;
    animation-delay: 0.25s;
    animation: fadeInNavbar 0.25s both ease;
    z-index:1;
    .brand-wrapper {
      display: flex;
      align-items: center;
      margin: auto;
      @include renderMobileNavView {
        margin-right: 0.25rem;
      }
    }

    

    .navbar-routes {
      font-size: 22px;
      height: 100%;
      display: inline;
      justify-content: flex-end;
      width: 54%;
      align-items: center;

      
      // .navbar-item {
      //   color: white;
      //   font-weight: 600;
      //   background-color: transparent;
      //   transition: color 0.2s ease-out, border-bottom-color 0.2s ease-out;
      //   border-bottom: 2.5px solid transparent;
      //   border-top: 2.5px solid transparent;
      //   display: flex;
      //   overflow-x: auto;
      //   overflow-y: hidden;
      //   height: 100%;
 
      //   @include renderMobileNavView {
      //     font-size: 0.95rem;
      //     padding: 0.75rem 0.2rem 0.75rem 0.2rem;
      //   }

      //   &:not(:first-child) {
      //     margin-left: 1.25rem;

      //     @include renderMobileNavView {
      //       margin-left: 0;
      //     }
      //   }

      //   &:hover {
      //     color: $color-blue-highlight;
      //     background-color: transparent;
      //   }

      //   &.is-active {
      //     color: $color-blue-highlight !important;
      //     border-bottom-color: $color-blue-highlight !important;
      //   }
      // }

      .navbar-profile{
        font-size:30px;
        margin-top:8px;
        margin-left:30px;
      }

    }

    .navbar-user-wrapper {
      margin-top:25px;
      flex:1;
    }

    .navbar-user-name {
      color:white;
      font-size: 20px;
    }

    .navbar-search{
      position: absolute;
      right:30px;
      top:10px;
      font-weight: bold;
      color: #44758C;
    }

    .navbar-search-box {
      margin-left:5px;
      width: 300px;
      background: transparent;
      border-color: #44758C;
    }
  }


}

@keyframes fadeInNavbar {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}*/

.navbar-dropdown-menu {
 margin-left: 10px;
}

.phrase-text-icon {
  width:40px;
  height:30px;
  cursor:pointer;
  font-size:1.4rem;
  padding-top:5px;
  padding-left: 15px;
  text-align:center;
}



