.accept-terms-frame {
   max-height: 600px;
   overflow: auto;
   padding:20px;
}

.message-text {
   width: 100%;
}

.accept-button-group{
   float:right;
   margin-bottom:50px;
   margin-top:20px;
   padding-bottom:20px;
   div {
      display:inline-block;
      margin-left:30px;
   }
}
