.market-analysis-row {
   display: flex;
   // flex-wrap: wrap;
   width: 100%;
   margin-bottom:6px;
   position: relative;
   font-size:1rem;

   .market-analysis-row-heading {
      font-size: 1rem;
   }

   .market-analysis-row-input{
      min-width: auto;
      width:calc(100% - 10px);
     // margin-right:5%;
      padding-right:24px;
   }

   .input-frame {
      position: relative;

      i {
        // font-size: 0.9rem;
         right: 10px; 
         position: absolute;
         transform: translate(0, -50%);
         top: 53%;
         // margin-top:1px;
         width: 25px;
         text-align: center;
         font-style: normal;
       }


   }
   input {
      font-size:1.2rem;
   }
}

.market-analysis-date-picker{
   margin-left:10px;
   margin-top:-6px;
   max-width:100px;
   min-width: 80%;
}

.market-analysis-delete-icon{
   //flex:0.5;
   width:24px;
   color:red;
   margin-left:6px;
   margin-top:2px;
   cursor: pointer;
}

.market-analysis-market-value-text{
   text-align: center;
   font-size:1.2rem;
   font-weight: bold;
   margin-top:10px;
   margin-bottom:10px;
}

.market-analysis-market-value-input{
   text-align: center;
   font-size:2rem;
   font-weight: bold;
   margin-bottom:10px;
   position: relative;
   
   input {
      width:350px;
      padding-right:20px;
      font-size:3rem;
      margin:auto;
   }

   i {
      font-size: 2rem;
      right: calc(50% - 175px); 
      position: absolute;
      transform: translate(0, -50%);
      top: 55%;
      width: 25px;
      text-align: center;
      font-style: normal;
    }
    
    .prefix{
      font-size: 2rem;
      left:calc(50% - 165px); 
      position: absolute;
      transform: translate(0, -50%);
      top: 55%;
      width: 25px;
      text-align: center;
      font-style: normal;
    }
}