.select-section-frame {
  display:flex;
  border:1px solid #767676;
  border-radius:2px;
  padding-top:10px;
  padding-bottom:6px;
  div {
     text-align:center;
     flex:1;
  }

  .select-section-caption{
     height:20px;
     font-size:1rem;
  }

  .select-box-item {
     display:flex;

     .select-box {
      flex:1;
      padding-top:2px;
      padding-right:5px;
         .the-box {
            float:right;
            border:1px solid $site-main-color;
            border-radius:2px;
            min-width:20px;
            min-height:20px;
            max-width:20px;
            max-height:20px;
            cursor:pointer;
         }

         .the-box:hover {
            background-color: $hover-color;
         }

         .selected-box {
            position: relative;
            overflow: hidden;
         }

         .selected-box:before, .selected-box:after {
            position: absolute;
            content: '';
            background: $site-main-color;
            display: block;
            width: 100%;
            height: 2px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        
        .selected-box:after {
            -webkit-transform: rotate(45deg);  
            transform: rotate(45deg);
        }


     }

     .select-box-caption {
      text-align: left;
      flex:1;
     }
  }
}