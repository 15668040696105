.summary-module-section {
   margin-left:10px;
   margin-top:20px;
   margin-right: 10px;
   margin-bottom:30px;
}

.summary-module-grade-container {
  // display: flex;
   // flex-wrap: wrap;
   width: 100%;
   margin-bottom:20px;

   .summary-module-tg{
      text-align: left;
      font-weight: bold;
      margin-right:100px;
      margin-left:20px;
      color:white;
      font-size: 1.4rem;
      justify-content: left;
      align-content: left;
      flex-direction: column;
      min-height:70px;
      max-height:70px;
      border-radius:15px;

      .summary-module-number{
         color:white;
         font-size: 0.8rem;
         border:0;
         margin-top:-8px;
      }

      .summary-module-tg-text{
         width:80px;
         padding-top:12px;
         text-align: center;
         //font-family:'Gothic A1', sans-serif;
         font-size:1.2rem;
      }




   }

   .summary-module-sum{
      font-size: 0.85rem;
      color:#DD392C;
      text-align:center;
   }
   .summary-module-categories{
      margin-left:100px;
      margin-right:20px;
      margin-top:-65px;
      border:1.5px solid #aaa;
      padding:4px;
      min-height:70px;
      background-color: white;
      border-radius:15px;

      .summary-module-categories-container{
         border-left:3px solid #aaa;
         padding-left:10px;
         min-height:50px;
         margin: 5px 5px 5px 15px;
      }
   }

   .summary-module-number{
      flex:1;
      border:1px solid #aaa;
      padding:4px;
   }     
}

hr.summary-line{
      margin-top: 0 !important;
      margin-bottom: 5px !important;
      border-top: 2px solid #DD392C !important;
      opacity: 10 !important;
      margin-left: 170px;
      width: 300px;

}

.summary-module-category-container{
   margin-bottom:2px;

   .summary-module-category-row{
      font-size:0.8rem;
      font-weight:bold;
   }
   
   .summary-module-assessment-row{
      font-size:0.8rem;
      font-weight:normal;
      margin-left:30px;
   }
   
   
   .summary-module-assessment-row:last-of-type{ 
      margin-bottom:10px;
   }
}


