.invisible{
 opacity: 0 !important;
}

.delete-icon{
   width:20px;
   color: #f55142;
   font-size:1rem;
   cursor: pointer;
}

.subtitle{
   font-size: 1rem;
   font-weight: bold;
}

.text-align-end{
   text-align:right;
}

.area-table-bra-header-row{
   margin-top: 15px;
}
.area-table-bra-header{
   text-align: center;
}


.area-table-input-floor-container {
   display: grid;
   grid-template-columns: 0.20fr 1fr;
   margin-left: 5px;
   margin-right: 10px;
   font-size: 0.8rem;
}

.area-table-input-rooms-container {
   display: flex;
   flex-direction: column;
}

.area-table-room{
   display: grid;
   grid-template-columns: 0.2fr 3fr;
}

.area-table-input-row-rooms {
   display: grid;
   grid-template-columns: 0.42fr 1.5fr repeat(3,0.55fr);
   font-size: 0.8rem;
   margin-left: 5px;
   margin-right: 10px;
   align-items: top;
}

.area-table-input-row {
   display: grid;
   grid-template-columns: 0.65fr repeat(4,0.47fr) 0.1fr 0.9fr;
   gap: 20px; 
   font-size: 0.8rem !important;
   margin-left: 5px;

   .input-container {
      width:100%;
      font-size:0.8rem;

      .disabled {
         border:1px solid #777;
         border-radius:2px;
         color:black;
      }

      .sum {
         background-color:$site-light-color;
      }

      .totalsum{
         background-color:$site-light-color;  
      }

      .input-numeric {
         margin-bottom:4px;
         font-size:0.8rem;
         text-align:right;
      }

      i {
         font-size: 0.9rem;
         right: 4px; 
         position: absolute;
         transform: translate(0, -50%);
         top: 10px;
         width: 25px;
         text-align: center;
         font-style: normal;
         color:black;
       }
   }
   
      input {
         width:100%;
         font-size:0.8rem;
      }

}

.area-table-floor-description{
   font-size:0.8rem !important;
   padding-top:4px !important;
   padding-bottom:3px !important;
   margin-top:2px !important;
   border:1px solid rgb(118, 118, 118) !important;
   border-radius:2px;
}


