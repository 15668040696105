
.bk-module {
   margin-left:10px;
   margin-top:10px;
   margin-right: 20px;

   input:read-only{
      background-color: $site-grey-light-color !important;
      color: #6c757d !important;
    }
    
    input:read-only:focus{
      background-color: $site-grey-light-color !important;
      color: #6c757d !important;
      box-shadow: none !important;
      outline: none !important;
    }
   
    textarea:read-only{
      background-color: $site-grey-light-color !important;
      color: #6c757d !important;
    }
    
    textarea:read-only:focus{
      background-color: $site-grey-light-color !important;
      color: #6c757d !important;
      box-shadow: none !important;
      outline: none !important;
    }
    

   .form-control {
      border-radius:2px !important;
      border-color:rgb(118,118,118) !important;
      box-shadow:none !important;
      padding-top:2px !important;
      padding-bottom: 2px !important;
      line-height: normal !important;
      font-family:Arial;
   }

   #form-control-thin-input{
      line-height: 10px !important;
   }

   textarea.form-control {
      min-height:0;// override bootstrap
   }
   
   input:focus {
      outline: none !important;
      border-color: black ;
      box-shadow: 0 0 0 1px black ;
   }


   .module-header {
      font-family:arial;
      font-size: 1.7rem;
      color: $hover-color;
      border-radius: 5px !important;
      font-family: $report-font;
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom:10px;
      display:flex;
    
      .description {
        flex:15;
      }
      .action-menu {
        flex:1;
        margin-left:10px;
        color: rgba(106,157,158,1);
        cursor: pointer;
      }
      .header-icon {
         margin-top:7px;
         margin-right:5px;
         min-width:20px;
      }
   }

   .module-color-default{
      background-color: $site-grey-color;
   }

   .module-color-shared{
      background-color: #e1c4ad;
   }

   .content-frame{
      padding: 10px;
      border-width: 5px !important;
      border-top-width: 0 !important;
      border-color: #E6E7E8 !important;
      border-style: solid !important;
      border-radius: 0 0 5px 5px !important;
   }

   .content-frame-blank{
      padding-top: 5px;
   }

   
   .module-row-main-header {
      font-weight: bold;
      font-size: 1.3rem;
   }

   .module-section-nomargin{
      margin-bottom:0px !important;
   }

   .module-section{
      padding-left:5px;
      padding-right:5px;
      margin-bottom:30px;


      .module-row {
         display:flex;
         width: 100%;
         margin-bottom:4px;


         .module-row-title {
            font-size: 0.8rem;
         }
         .module-row-title-big {
            font-size: 1rem;
         }

         .module-row-compact{
            line-height: 0.8rem;
         }
         .border-single {
            border-bottom: 1px solid black;
         }
         .border-double {
            border-bottom: 4px double black;
         }
      
         .module-row-title-big-bold {
            font-size: 1.2rem;
            font-weight: bold;
            border-bottom:2px solid #E6E7E8;
            width:60%;
            padding-bottom:5px;
            margin-bottom:5px;
         }


         .module-row-header {
            font-weight: bold;
            font-size: 1rem;
            padding-right:10px;
            text-align:right;
         }

         #module-header-left{
            text-align:left !important;
         }
      
         .adjusted {
            padding-right:20px;
         }

         .module-row-input {
            padding-left:10px;
            width:calc(100% - 20px);
            min-height:24px;
            position: relative;
         }

         .module-row-select {
            padding-left:10px;
            width:calc(100% - 20px);
            min-height:24px;
            position: relative;
         }

         .module-row-input-end {
            padding-left:10px;
            width:calc(100% - 10px);
            min-height:24px;
         }

         .module-row-input-double-margin {
            padding-left:10px;
            width:calc(100% - 40px);
            min-height:24px;
         }

         .module-row-input-double-margin-suffix {
            position: relative;

            input {
               width:calc(100% - 40px);
               padding-right:24px;
            }

            select {
               width:calc(100% - 40px);
            }

            i {
               // font-size: 0.9rem;
                  right: 40px; 
                  position: absolute;
                  transform: translate(0, -50%);
                  top: 50%;
                  // margin-top:1px;
                  width: 25px;
                  text-align: center;
                  font-style: normal;
               }      
         }

         .module-row-input-with-suffix {
            position: relative;
            

            input {
               width:calc(100% - 20px);
               padding-right:24px;
            }

            select {
               width:calc(100% - 20px);
            }

            i {
            // font-size: 0.9rem;
               right: 20px; 
               position: absolute;
               transform: translate(0, -50%);
               top: 50%;
               // margin-top:1px;
               width: 25px;
               text-align: center;
               font-style: normal;
            }            
         }

         .module-row-input-with-suffix-new {
            position: relative;
            

            input {
               width:calc(100% - 5px);
               padding-right:25px;
            }

            select {
               width:calc(100% - 5px);
            }

            i {
            // font-size: 0.9rem;
               right: 5px; 
               position: absolute;
               transform: translate(0, -50%);
               top: 50%;
               // margin-top:1px;
               width: 25px;
               text-align: center;
               font-style: normal;
            }            
         }

         .module-row-input-with-suffix-large {
            position: relative;
            

            input {
               width:calc(100% - 20px);
               padding-right:44px;
            }

            select {
               width:calc(100% - 20px);
            }

            i {
            // font-size: 0.9rem;
               right: 20px; 
               position: absolute;
               transform: translate(0, -50%);
               top: 50%;
               // margin-top:1px;
               width: 45px;
               text-align: center;
               font-style: normal;
            }            
         }


         .module-row-date-field {
            margin-bottom:6px;
            max-width:160px;
            border: 1px solid #777;
            border-radius:2px;
            font-size:1rem;
            line-height: 22px;
            text-align:center;
            min-height:22px;
         }

         .module-row-date-picker{
            display: grid;
            grid-template-columns: 4fr 1fr;
            column-gap: 5px;
            margin-left:10px;
            margin-top:-6px;
            max-width:120px;
            min-width: 90%;
         }

         #clear-date-icon{
            cursor: pointer;
            color:#555;
            font-size:1.3rem;
         }
         
         .margin-left {
            margin-left:20px;
         }


         .delete-icon{
            width:20px;
            padding-left:0;
            font-size:1rem;
            color:red;
            margin-top:0;
            cursor: pointer;
         }

         .trash-can-icon {            
            padding-left:15px;
            font-size:1rem;
            color:#555;
            margin-top:0;
            cursor: pointer;
         }

         .cog-icon {            
            width:40px;     
            padding-left:20px;
            margin-top:2px;
            cursor:pointer;
         }

         .action-icon {
            width:40px;     
            padding-left:20px;
            margin-top:2px;
            cursor:pointer;
         }
         
         .icon-placeholder{
            width:40px;     
            padding-left:20px;
            margin-top:2px;
         }
      }


      .infoText{
         font-size: 12px;
         font-weight: bold;
         margin-right: 41px;
      }

      .margin-right-10 {
         margin-right:10px;
      }

      .margin-right-20 {
         margin-right:20px;
      }

      .margin-right-30 {
         margin-right:30px;
      }

      .padding-left-10 {
         padding-left:10%;
      }

      .padding-left-20 {
         padding-left:20px;
      }


      .padding-top-10 {
         padding-top:10px;
      }


      .padding-right-10 {
         padding-right:10px;
      }

      .padding-right-20 {
         padding-right:20px;
      }

      .padding-right-30 {
         padding-right:30px;
      }

      .module-section-icon {
         font-size: 1rem;
         color: white;
      }
      
      .margin-bottom-10 {
         margin-bottom: 10px;
      }

      .bold-forced{
         font-weight: bold !important;
      }
   }

   .forest-text-padding{
      margin-top: 20px !important;
   }

   .float-right {
      float:right;
   }

}

