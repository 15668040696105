@media only screen and (max-width: 575px) { 

    .home-header{
        margin: 0 !important;
        padding: 0 !important;
    }

    #home-card {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    #company_logo {
        width: 75% !important;
    }

    #company_name {
        font-size: $text-md;
    }
    
    #company_subtitle {
        font-size: $text-s;
    }
  
    #company_phrase {
      font-size: 30px;
    }
    
    #customer_review_web {
        margin: 0;
        padding: 0;
    } 

    .home-row-title{
        margin: 0 !important;
        text-align: center;
    }

    .home-function-content-row {
        margin: 0 !important;
    }

    #func_icon {
        margin-top: 50px !important;
    }

    #cards_web {
        display: none;
    }
    .home-customer-text {
        font-size: 24px;
    }

    #home-customer-text-lg {
        font-size: 45px;
    }

    #rapport-text {
        font-size: 20px;
    }

    #home-info-text-md{
        font-size: 20px;
    }

    #home-info-text-title{
        font-size: 30px;
    }

    #about-text{
        display: none;
    }

    .bk-footer {
        display: none;
    }
  }

  @media only screen and (max-width: 992px) { 
   
    #func_icon {
        margin-top: 50px !important;
    }

    .home-customer-text{
        margin-top: 25px !important;
    }

    .home-function-text{
        font-size: $text-sm;
    }
    .home-function-content-row{
        margin: 0 !important;
        padding: 0 !important;
    }
    .home-row-title{
        font-size: $text-md;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    

  }
  

@media only screen and (min-width: 576px) { 
    #customer_review_mobile {
        display: none;
    }
    .cards_mobile {
        display: none;
    } 
    #about-mobile-img{
        display: none;
    }

}

