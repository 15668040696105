
$sidebar-bg-color: $site-main-color;
$submenu-bg-color: $hover-color;
$icon-bg-color:transparent;
$sidebar-color: $element-color;
$sidebar-width: 200px;


@import '~react-pro-sidebar/dist/scss/styles.scss';


.admin-container {
   display: grid;
   grid-template-columns:  0.5fr 9fr;
   /*flex: auto;
   width:100%;
   display:flex;*/
   overflow-x: hidden;
   background-color: $element-color;
}


.admin-menu-icon-with-number{
   position:relative;
   line-height: 20px !important;

   .admin-menu-icon-number {
      position:absolute;
      width:20px;
      height:20px;
      color: white;
      text-align:center;
      background-color: #a61122;
      border-radius: 50%;
      top:-10%;
      right:75%;
      font-weight:bold;
      font-size: 0.75rem !important;
   }

}

.admin-menu-text {
   font-size: $text-sm !important;
}

.admin-menu-icon{
   font-size:2.0rem;
}

.admin-menu-version{
   font-size:0.8rem;
}


.customer-text-trim {
   white-space: nowrap;
   overflow: hidden;
 }

.customer-table-content{
   height: calc(100vh - 96px - 70px);
   overflow-y:scroll;
   background-color: $site-background-color;
}

.search-container{
   display: grid;
   grid-template-columns:  3fr 2fr 7fr;
   column-gap: 10px;

   .admin-count {
      margin-top:10px;
      margin-left:20px;
      font-size:14px;
   }
}

.customer-table-header{
   display: grid;
   grid-template-columns:  repeat(4, 1fr) 2.5fr 2.5fr repeat(2, 1fr) repeat(3, 0.5fr) 0.45fr;
   column-gap: 8px;
   font-weight:bold;
   font-size:12px;
   padding: 3px;
   padding-left: -3px !important;
   background-color: $site-light-color;
   color: $hover-color;
   font-family: $title-font;
   overflow-x: hidden;
}

.status-icons-container{
   text-align: center;
   display: flex;
   padding-left:10px;
   .admin-icon {
      margin-bottom:-2px;
      margin-top:-2px;
   }
   .green{
      color: green !important;
   }
   .red{
      color: red !important;
   }
   .grey{
      color: rgb(212, 204, 204);
   }
   .transparent {
      color:transparent;
   }
}

.customer-row {
   display: grid;
   grid-template-columns:  repeat(4, 1fr) 2.5fr 2.5fr repeat(2, 1fr) repeat(3, 0.5fr) 0.3fr;
   column-gap: 8px;
   font-size: 12px;
   color: $hover-color;
   cursor:pointer;
   padding: 3px;
   overflow-x: hidden;
   font-family: $title-font;

   &:hover {
      background-color: $hover-color;
      color: $element-color;
   }

   .align-right{
      text-align: end;
   }
}

.admin-statistics-header {
   font-size:1.8rem;
   font-weight:bold;
   border-bottom: 2px solid black;
   text-align: start;
   margin-top: 10px;
   margin-bottom: 10px;
}

.customer-statistics-row {
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   margin-top: 20px;
}

.customer-statistics-row-item{
   flex: 50% !important
}

.customer-card-info-row{
   display: grid;
   grid-template-columns: 0.4fr 2fr;
   gap: 5px;
   white-space: nowrap;
   margin-bottom: 10px;
   font-size: $text-xxs;
}

.customer-card-info-row-long{
   display: grid;
   grid-template-columns: 0.6fr 2fr;
   gap: 5px;
   white-space: nowrap;
   margin-bottom: 10px;
   font-size: $text-xxs;
}



.customer-row-white {
   background-color: white;
   
}
.customer-row-grey {
   background-color: $site-light-color;  
}

@media only screen and (max-width: 576px) { 
   .customer-table-content{
      width: 95%;
      
   }
   .customer-table-header{
      margin-left: 1px !important;
      width: 95%;
      
   }

   #margin-mobile{
      padding-top: 15px !important;
      padding-bottom: 15px !important;
   }

   .mobile-display-none{
      display: none;
   }
   
}

.customer-main-card{
   .main-title{
      font-size: $text-m;
      font-weight: bold;
   }

   .main-subtitle{
      font-size: $text-s;
   }

   @media only screen and (max-width: 576px) { 
      .main-btn{
        
      }

      hr{
         width: 75% !important;
      }
   }
   
}

.customer-report-card{

   .report-title{
      font-size: $text-m;
      font-weight: bold;
   }

   .report-subtitle{
      font-size: $text-s;
   }

   @media only screen and (max-width: 576px) { 
      .report-login-info{
         text-align: center;
      }

      hr{
         width: 75% !important;
      }
   }
}

@media only screen and (max-width: 576px) { 

   .customer-project-card-container{
      display: none;
   }
   .customer-other-calc-card{
      display: none;
   }
   
}

.project-table{
   display: flex;
   flex-direction: column;

   .project-table-grid{
     display: grid;
   //   grid-template-columns: 
   //   1fr 1fr 2.5fr 2fr;
     grid-template-columns: 
     1.5fr 1.5fr 2.5fr;
     text-align: center;
     gap:1rem;
     margin-right: 1rem;
     margin-left: 1rem;
   }
   
   .project-table-header-row{
     align-items: center;
     background-color: #C2D7E8;
     height: $row-height;
     font-family: $report-font;
     color: $hover-color;
     font-size: 14px;
     font-weight: bold;
     cursor:default;
   }

   .project-table-data-container{
     overflow-y:scroll;
   }
 
   .project-table-data-row{
     align-items: center;
     background-color: #C2D7E8;
     height: $row-height;
     font-family: $report-font;
     color: $hover-color;
     font-size: 14px;
     cursor:pointer;
     justify-content: center;
   }

   .text-align-start{
      text-align: start;
   }
 
   .text-trim{
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
   }
 
   .project-table-data-row:hover{
     background-color: $hover-color !important;
     color: $element-color;
   }

   .white-row{
     background-color: #ffffff !important;
   }
   .grey-row{
     background-color: #d1d8e1 !important;
   }
 }

.customer-projects-row{
   display: grid;
   grid-template-columns: 1.5fr 1fr;
   gap: 30px;
}

.customer-projects-card{
   display: flex;
   flex-direction: column;
   background-color: $bk-title-color;
   padding: 1rem;
   margin-bottom: 2rem;
}

.customer-filter-search-group{
   display: grid;
   grid-template-columns: 0.5fr 1fr;
   gap: 25px;
   margin-left: 2rem;
   margin-right: 2rem;
   margin-bottom: 1rem;

   .filter{
     display: flex;
     align-items: center;
     gap: 25px;
   }
 }

.customer-shared-list{
   background-color: white;
   height: 17rem;
   overflow-y: auto;
}
.customer-shared-list-content{
   display: flex;
   flex-direction: column;
   gap: 6px;
   padding: 15px;
}
.customer-shared-list-content-entry{
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.customer-shared-list-button{
   background-color: $site-green-color;
   border: 0 !important;
   font-family: $title-font;
   color: white;
   padding-left: 8px;
   padding-right: 8px;
   border-radius: 8px;
}

.customer-shared-list-header{
   display: flex;
   justify-content: space-between;
   align-items: center;
   background-color: $site-main-color;
   color: white;
   //font-size: 20px;
   padding: 6px;
   padding-left:15px;
   padding-right:15px;
   font-family: $report-font;
   font-weight: bold;
}


.messagebox{
   display: flex;
   flex-direction: row;
   //border-radius: 5px;
   //background-color:$site-main-color;
   padding-left: 50px;
   padding-right: 50px;
   //box-shadow: 0px 3px 0px $site-green-color;

   .messagebox-text{
      color: $site-black-color;
      font-size: $text-sm;
      font-family: $title-font;
      //text-align: center;
      //padding-top: 20px;
      flex: 80%;
   }

   .messagebox-number-zero{
      color: $site-green-color;
      font-size: $text-m;
      padding-left: 5px;
   }
   .messagebox-number-nonzero{
      color: #a61122;
      font-size: $text-m;
      padding-left: 5px;
   }
   .messagebox-btn{
      background-color: $site-green-color;
      color: $element-color;
      font-size: $text-sm;
      font-family: $title-font;
      text-align: center;
      border-radius: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      //margin-top: 20px;
      margin-bottom: 10px;
      text-decoration: none !important;
      flex: 20%;
   }
   .messagebox-btn:hover{
      background-color: $hover-color;
   }
}

@media only screen and (min-width: 576px) { 

   .admin-sidebar-btn{
      display: none !important;

   }
   .admin-sidebar-close-btn{
      display: none !important;
   }
}

.admin-sidebar-close-btn{
  text-align: end;
}

@media only screen and (max-width: 576px) { 

   .graph_frame{
      display: none;
   }
}

.graph_frame{
   background-color: $site-grey-color;
   margin-left: 3px;
   margin-right: 3px;
   border-radius: 5px;
   padding: 5px;
   padding-right: 30px !important;
}

.admin-card-frame-n {

   border-radius: 5px;
   background-color:$site-main-color;
   padding-bottom: 5px;
   box-shadow: 0px -7px 0px $site-green-color;

      .admin-card-title {
         text-align:center;
         font-size: $text-md;
         color: $element-color;
         font-family: $base-font;
         padding-top: 10px;
      }

      .admin-card-main-number{
         text-align:center;
         font-size:$text-lg;
         color: $element-color;
         font-family: $base-font;
         font-weight: bold;
         padding-bottom: 10px;
      }

      .admin-card-text{
         font-size:$text-xs;
         padding-left:10px;
         color: $element-color;
         font-family: $base-font;
      }
      .admin-card-number{
         font-size:$text-xs;
         color: $element-color;
         text-align:right;
         padding-right:10px;
         font-family: $base-font;
         font-weight: bold;
      }
   }

.ssb-container {
   height: 100%;
   overflow-x: hidden;
}

.message-title-row{
   display: grid;
   grid-template-columns: 1fr 1fr 4fr 0.7fr;
   column-gap: 10px;
   padding-left: 30px;
   padding-right: 30px;
   font-size: smaller;
}
.message-row{
   display: grid;
   grid-template-columns: 1fr 1fr 4fr 0.7fr;
   column-gap: 10px;
   padding-top: 10px;
   padding-bottom: 10px;
   margin-left: 30px;
   margin-right: 60px;
   font-size: small;
   border-top: solid 1px $site-grey-color;
}

.message-button-container{
   display: grid;
   grid-template-columns: 1fr 1fr;
   column-gap: 10px;
}

.message-button{
   background-color: $site-main-color;
   color: $element-color;
   border-radius: 20px;
   max-width:85px;
   text-align: center;
   align-items: center;
   padding: 3px;
   font-size: $text-xs;
   font-family: $base-font;

   &:hover{
      background-color: $hover-color;
      cursor: pointer;
   }
  
}

#message-button-archive{
   background-color: $site-grey-color !important;
   color: $hover-color;

   &:hover{
      background-color: $hover-color !important;
      color: $element-color;
   }
}

.message-over{
   overflow: hidden;
}

.message-icon {
   font-size:1rem;
}

.subscription-title-row{
   display: grid;
   grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
   column-gap: 10px;
   padding-left: 10px;
   padding-right: 10px;
   font-size: smaller;
}

.subscription-row{
   display: grid;
   grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
   column-gap: 10px;
   padding-top: 10px;
   padding-bottom: 10px;
   padding-left: 10px;
   padding-right: 10px;
   font-size: small;
   border-top: solid 1px $site-main-color;
}


/*.message-container {
   height: 100%;
   overflow-x:hidden;

   .message-row{
      padding-left:10px;
      padding-right:50px;
      padding-top:10px;
      padding-bottom:10px;

      div {
         font-size:0.9rem;
      }
   }
}*/

.customer-share-container{
   height: 20rem;
   overflow-y: auto;
   margin-top: 1rem;
   padding-right: 1rem;
}

.customer-no-search-result{
   margin-top: 1rem;
   height: 20rem;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 2rem;
   color:#a39b8c;
   background-color: #f4f4f3;
}

.customer-result-row {
   display: grid;
   grid-template-columns:  repeat(3, 1fr);
   font-size: 15px;
   color: $hover-color;
   cursor:pointer;
   padding: 5px;
   overflow-x: hidden;
   font-family: $title-font;
   padding-left: 1rem;

   &:hover {
      background-color: $hover-color;
      color: $element-color;
   }

   .align-right{
      text-align: end;
   }
   .align-center{
      text-align: center;
   }
}

.customer-share-row {
   display: grid;
   grid-template-columns:  repeat(2, 1fr) 1.5fr 0.2fr;
   font-size: 15px;
   color: $hover-color;
   cursor:pointer;
   padding: 5px;
   overflow-x: hidden;
   font-family: $title-font;
   padding-left: 1rem;
   align-items: center;

   &:hover {
      background-color: $hover-color;
      color: $element-color;
   }

   .align-right{
      text-align: end;
   }
   .align-center{
      text-align: center;
   }
}

.customer-share-row-selected{
   background-color: $hover-color;
   color: $element-color;
}

.index-row{
   background-color: $site-main-color;
   color: $element-color;
   font-family: $title-font;
   font-size: $text-md;
   font-weight: bold;
}

.index-data-row {
   background-color: $site-light-color;
   color: $hover-color;
   font-family: $title-font;
   font-size: $text-sm;
}

.index-btn{
   background-color: $site-main-color;
   color: $element-color;
   font-family: $base-font;
   font-size: $text-sm;
   text-align: center;
   border-radius: 10px;
   width: 100px;
 

   &:hover {
      background-color: $hover-color;
      cursor: pointer;
   }
}

.admin-checkbox{
   padding-left: 1rem;
   padding-top: 0.5rem;
   font-size:$text-sm; 
}

.admin-checkbox-box {
   color: $site-green-color !important;

   &:hover{
      background-color: transparent !important;
   }
}

.admin-checkbox-text {
   padding-top: 20px;
}

.admin-title{
   font-size: $text-md;
}


.links-container{
   display: flex;
   flex-direction: column;
   padding: 4rem;


   .link-row{
      font-family: $title-font;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      gap: 0.7rem
   }
}

.update-message-container{
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 4rem;

   .update-message-row{
      width: 100%;
      display: flex;
      gap: 3rem;
      align-items: center;
      font-family: $report-font;
      font-size: $text-s;
      border-bottom: 2px solid $site-main-color;
      height: auto;
      padding: 1rem;
   }
}


@media only screen and (min-width: 576px) {
   .message-row-message{
      hr{
         display: none;
      }
   }
} 
@media only screen and (max-width: 576px) { 
   .message-mobile{
      display: none;
   }

   .message-mobile-padding{
      padding-top: 20px !important;
     }

   .admin-checkbox{
      padding: 0;
   }

   
}
