.detail-grid-row-declaration {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 0.5fr 0.5fr 0.1fr;
    align-items: center;
    gap: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: start;
    margin-bottom: 0.5rem;
}
.detail-grid-row-sum-declaration {
    display: grid;
    grid-template-columns: 3.75fr 0.5fr 0.1fr;
    align-items: center;
    gap: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: bold;
    text-align: end;
    margin-bottom: 0.5rem;
}

.category-headers {
    align-items: end;
    font-size: $text-xxs;
}

.header-align-center {
    text-align: center;
}

.detail-input {
    height: 1.5rem;
}

.delete-icon-pointer {
    cursor: pointer;
}

.select-adjustment{
     text-align: center;
     width:6rem;
     margin-left:1rem;
}