.priceguide-wrapper{
   width:100%;
   display:flex;
}

.priceguide-category{
   flex:2;
   cursor: pointer;

   .priceguide-heading {
      font-size:1.4em;
   }

   .category-area {
      margin-top:20px;
      padding: 4px;
      border: 1px solid #999;
      height: 500px;
      overflow-y: scroll;
      font-size: 12px;
      margin-right:50px;
   }

   .selected {
      font-weight: bold;
   }
}

.priceguide-list{
   flex:5;
   cursor: pointer;

   .priceguide-list-header{
      display:flex;

      .priceguide-header-text{
         text-align: right;
         padding-right:20px;
      }

      .priceguide-header-error {
         text-align: right;
         padding-right:20px;
         color:red;
         font-size: 1em;  
      }

      .hidden {
         display: none;
      }
   }

   .priceguide-heading {
      font-size:1.4em;
   }

   .product-area {
      margin-top:20px;
      padding: 4px;
      border: 1px solid #999;
      height: 500px;
      overflow-y: scroll;
      font-size: 12px;
      background-color: #fff;
   }

   .selected {
      font-weight: bold;
   }


}


.priceguide-product-row{
   display:flex;

   .priceguide-product-row-last{
      text-align: right; 
      padding-right: 5px;
   }
}