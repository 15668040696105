@import '../../../assets/style/scss/base/variables.scss';
@import '../../../assets/style/scss/base/byggekost.scss';


.category-header {
   font-size: 1.2rem;
   font-weight: bold;
   font-family:$report-font;
   color:black;
   background: $site-light-color;
   padding-left:1rem;
   padding-right:1rem;
   border-radius: $site-border-radius $site-border-radius 0px 0px;
   display:grid;
   grid-template-columns: auto 2.5fr 1.1fr 1fr 0.5fr auto;
   align-items: center;
   gap: 0.6rem;

   .category-action-menu {
      display: flex;
      align-items: center;
      font-size: 1rem;
      color: $site-black-color;
      cursor: pointer;
      gap: 1rem;
   }

   .status-icon {
      display: flex;
      font-size: 1rem;
      justify-self: center;
      align-self: center;
   }
   .status-icon-green {
      color: green;
   }
   .status-icon-yellow {
      color: yellow;
   }
   .status-icon-red {
      color: red;
   }
   .status-icon-transparent {
      color: transparent;
      border: 1px solid black;
      border-radius: 100px;
   }

   .input-field {
      display:flex;
      input {
         background-color: white;
         color:$bk-header-color;
         font-size:1rem;
         border-radius: 3px !important;
         outline: none !important;
         border: none !important;
         box-shadow: none!important;
      }
   }

   .tag {
      font-size: $text-xs;
      background-color: $site-light-color;
      color:$hover-color;
      padding-left: 2rem;
   }


   .description-mini {
      overflow:hidden;
   }

   // .input-field-2 {
   //    flex:5;
   //    padding-left:40px;
   //    display:flex;

   //    input {
   //       max-width:250px;
   //       padding-left:6px;
   //       padding-bottom:2px;
   //       background-color: $bk-light-blue;
   //       color:$bk-header-color;
   //       font-size:1.2rem;
   //    }
   // }

   // .search-icon {
   //    margin-top:3px;
   //    margin-right:5px;
   //    min-width:10px;
   // }

}
