﻿/* react-toastify NPM PACKAGE CSS OVERRIDES */
.Toastify__toast {
  border-radius: $rt-toast-border-radius;
}

/* OVERRIDES & GENERAL STYLES */
* {
  margin: 0;
}

html,
body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  font-family: Arial !important;
  background-color: #e6e7e8 !important;
}

body {
  font-size: 12px;

  @media screen and (min-width:$screen-sm) {
    font-size: 12px;
  }

  @media screen and (min-width:$screen-lg) {
    font-size: 16px;
  }

  @media screen and (min-width:$screen-xl) {
    font-size: 16px;
  }

}


body,
button,
input,
optgroup,
select,
textarea {
  font-family: Arial;
  // font-size: 1em;
  padding: 2px;
}



.content-frame {
  padding: 10px;
  border-width: 5px !important;
  border-top-width: 0 !important;
  border-color: #E6E7E8 !important;
  border-style: solid !important;
  border-radius: 0 0 5px 5px !important;
}


.box {
  padding: 1.75rem;
  border-radius: 0.25rem;
  border-color: rgba(0, 0, 0, .05);
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .08), 0 5px 20px 0 rgba(0, 0, 0, .06);

  &.login-box {
    padding: 1.5rem;
  }

  &.container-box {
    min-height: 430px;
  }
}

// .login-error-message{
//   color:red;
//   margin-bottom: 4px;
// }

#login-img {
  opacity: 0.9;
  padding-bottom: 0.75rem;
}

.section {
  //min-height: 30rem;
}

.section-login {
  padding: 1rem 1.5rem;
}

// code {
//   padding: 3px 4px;
//   font-size: 0.94em;
//   word-break: break-word;
//   border-radius: 0.25rem;
//   color: $color-hero-is-dark;
//   background-color: rgba(9, 211, 172, 0.13);
//   font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
// }

// .hero.is-dark {
//   background-color: $color-hero-is-dark;

//   .hero-body {
//     padding: 1.5rem;

//     img {
//       user-select: none;
//     }
//   }

//   .title {
//     color: $color-blue-highlight;
//   }
// }


.form-columns {
  h5 {
    margin-bottom: 3rem !important;
  }

  .title:not(.is-spaced)+.subtitle {
    margin-top: -1rem;
  }

  .form-control-group {
    min-height: 65px;
  }
}

.is-horizontal-center {
  align-items: center;
  justify-content: center;
}

.button {
  font-weight: 600;
  border-radius: 3px;

  &.is-light {
    background-color: rgba(9, 30, 66, .0725);

    &:hover {
      background-color: rgba(9, 30, 66, 0.11);
    }
  }
}

.remember-me-field {
  display: flex;
  margin-top: 10px;
  width: 100%;

  label {
    margin-left: 0;
    flex: 1;
  }

  span {
    font-size: 1rem;
  }
}

.forgot-password-field {
  text-align: right;
  flex: 1;

  div {
    cursor: pointer;
    color: $bk-title-color;
  }
}

// .incrementer-buttons {
//   align-items: initial;
//   margin-bottom: .75rem !important;

//   > .button {
//     margin-bottom: 0;
//     min-width: 6.5rem;

//     svg {
//       margin: auto;
//       font-size: 1.4em;
//     }

//     &.plus {
//       svg {
//         color: $cyan;
//       }
//     }

//     &.minus {
//       svg {
//         color: $red;
//       }
//     }

//     &:not(:last-child) {
//       margin-right: 0.75rem !important;
//     }
//   }
// }

.dashboard-wrapper {
  min-height: 60rem;
  padding-bottom: 3rem;

  .card-content {
    padding: 1rem 1.5rem;

    .content li+li {
      margin-top: 1em;
    }

    .dashboard-info {
      padding: 1.5em !important;
    }
  }

  hr {
    width: 50%;
    margin: 0.5rem auto;
    background-color: rgba(0, 0, 0, 0.06);
  }
}

.dashboard-link {
  color: #363636;
  font-weight: 700;
  padding: 0.25em 0.5em 0.25em;
  margin-right: 0.25em;
  transition: background-color 0.2s ease-out, border-bottom-color 0.2s ease-out;

  &.react {
    background-color: rgba(32, 156, 238, 0.18);
    border-bottom: 1px solid rgba(32, 156, 238, 0.725);

    &:hover {
      background-color: rgba(32, 156, 238, 0.3);
      border-bottom-color: rgba(32, 156, 238, 1);
    }
  }

  &.redux {
    background-color: rgba(118, 74, 188, 0.18);
    border-bottom: 1px solid rgba(118, 74, 188, 0.725);

    &:hover {
      background-color: rgba(118, 74, 188, 0.3);
      border-bottom-color: rgba(118, 74, 188, 1);
    }
  }

  // &.bulma {
  //   background-color: rgba(0, 196, 167, 0.18);
  //   border-bottom: 1px solid rgba(0, 196, 167, 0.725);

  //   &:hover {
  //     background-color: rgba(0, 196, 167, 0.3);
  //     border-bottom-color: rgba(0, 196, 167, 1);
  //   }
  // }

  &.aspcore {
    background-color: rgba(118, 74, 188, 0.18);
    border-bottom: 1px solid rgba(118, 74, 188, 0.725);

    &:hover {
      background-color: rgba(118, 74, 188, 0.3);
      border-bottom-color: rgba(118, 74, 188, 1);
    }
  }

  &.sass {
    background-color: rgba(198, 83, 140, 0.18);
    border-bottom: 1px solid rgba(198, 83, 140, 0.725);

    &:hover {
      background-color: rgba(198, 83, 140, 0.3);
      border-bottom-color: rgba(198, 83, 140, 1);
    }
  }

  &.typescript {
    background-color: rgba(41, 78, 128, 0.18);
    border-bottom: 1px solid rgba(41, 78, 128, 0.725);

    &:hover {
      background-color: rgba(41, 78, 128, 0.3);
      border-bottom-color: rgba(41, 78, 128, 1);
    }
  }
}

.pagination-group {
  .button:not(:last-child) {
    margin-right: 0.75rem !important;
  }

  >a {
    width: 8em;

    &:nth-child(1) {
      margin-left: auto;
    }

    &:nth-child(2) {
      margin-right: auto;
    }
  }
}

.icon-clickable {
  pointer-events: visible !important;
  cursor: pointer;

  &:hover {
    color: #363636 !important;
    opacity: 0.7;
  }
}

.table.is-fullwidth {
  @media only screen and (max-width: 449px) {
    font-size: 0.8rem;
  }
}

.wrapper {
  min-height: 100%;
  //height: auto !important;
  //height: 100%;
  //height:978px;
  margin: 0 auto -60px;
  /* the bottom margin is the negative value of the footer's height */
}

.bk-header-light {
  width: 100%;
  height: 60px;
  background: #F1F4F5;
  padding-top: 10px;

  .logo {
    margin-top: -20px;
    width: 200px;
  }
}

.header-message-container{
  display: grid;
  grid-template-columns: 5fr auto;
  gap: 2rem;
  text-align: center;
  font-size: 0.9rem;
  font-family: $title-font;
  font-weight: 600;
  color: #2D2927;
  justify-content: center;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.header-message-inner-container{
  display: flex;
  justify-content: center;
  gap:1rem
}
.message-container{
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  gap:1rem
}

.bg-update{
  background-color: #FFC989;
}
.bg-message{
  background-color: #90B188;
}

.header-message-close-icon{
  font-size: $text-m;
  cursor: pointer;
}
.header-message-info-icon{
  font-size: $text-m;
}


.bk-footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0;
  background-color: none;

  @media only screen and (max-width: 769px) {
    font-size: 1rem;
  }
}

.bk-footer>div {
  text-align: center;
  font-size: 1rem;
  color: #44758C;
}

.push {
  height: 60px;
  /* .push must be the same height as .footer */
}

#root {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.clear-both {
  clear: both;
}

.display-none {
  display: none;
}

.display-flex {
  display: flex;
}

input:read-only{
  background-color: $site-grey-light-color !important;
  color: #6c757d !important;
}

input:read-only:focus{
  background-color: $site-grey-light-color !important;
  color: #6c757d !important;
  box-shadow: none !important;
  outline: none !important;
}

textarea:read-only{
  background-color: $site-grey-light-color !important;
  color: #6c757d !important;
}

textarea:read-only:focus{
  background-color: $site-grey-light-color !important;
  color: #6c757d !important;
  box-shadow: none !important;
  outline: none !important;
}


.visibility-hidden {
  visibility: hidden
}

.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-grid {
  display: flex
}


.flex-015 {
  flex:0.15;
}

.flex-05 {
  flex: 0.5;
}

.flex-1 {
  flex: 1;
}

.flex-15 {
  flex: 1.5;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-65 {
  flex: 6.5;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 10;
}

.search-cursor {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}


.form-group {
  margin-bottom: 2px;
}

#form-control-thin-input {
  height: 25px;
  font-size: $text-xxs;
  padding: 2px;
  padding-left: 10px;
}

.form-control-large {
  width: 200px !important;
  padding: 6px 6px !important;
}

.form-control-small {
  margin-left: 62px !important;
  width: 138px !important;
  padding: 6px 6px !important;
}

.form-control-large-text {
  width: 200px !important;
  padding: 6px 6px 6px 10px !important;
}

.form-control-extra-large-text {
  max-width: 100% !important;
  padding: 6px 6px 6px 10px !important;
}

.form-control-note {
  min-width: 400px !important;
  width: 99% !important;
  padding: 6px 6px 6px 10px !important;
  margin-top: 20px;
  box-shadow: none !important;
}

#form-dialog-title {
  font-family: $base-font;
  background-color: $site-main-color;
  color: $element-color;
}

.form-dialog-btn {
  font-family: $base-font !important;
  color: $element-color !important;
  background-color: $site-main-color !important;
  border-radius: 10px !important;
  font-size: $text-xs !important;

  &:hover {
    background-color: $hover-color !important;
  }
}

.question-image {
  width: 20px;
  background-color: #a9a9a9;
  padding: 1px;
  border-radius: 10px;
  margin-left: 20px;
  cursor: pointer;
}

.question-image-left {
  width: 20px;
  background-color: #a9a9a9;
  padding: 1px;
  border-radius: 10px;
  margin-right: 20px;
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.text-red {
  color: red;
}

.bold-important {
  font-weight: bold !important;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



.no-image {
  width: 100%;
  height: 100%;
  background:
    linear-gradient(to top left,
      rgba(170, 170, 170, 0) 0%,
      rgba(170, 170, 170, 0) calc(50% - 0.8px),
      rgba(170, 170, 170, 1) 50%,
      rgba(170, 170, 170, 0) calc(50% + 0.8px),
      rgba(170, 170, 170, 0) 100%),
    linear-gradient(to top right,
      rgba(170, 170, 170, 0) 0%,
      rgba(170, 170, 170, 0) calc(50% - 0.8px),
      rgba(170, 170, 170, 1) 50%,
      rgba(170, 170, 170, 0) calc(50% + 0.8px),
      rgba(170, 170, 170, 0) 100%);
  color: $bk-green-dark;
  text-align: center;
  padding-top: 29%;
  font-size: 1.5rem;
}


.page-container {
  flex: auto;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background: #F1F4F5;
  padding-bottom: 100px;
}

.page-wrapper {
  width: 95%;
  min-height: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 60px;
  padding: 10px;
  background-color: white;
}

.dialog-header {
  color: #F1F4F5;
  font-family: $title-font !important;
  background: #44758C;
  margin-bottom: 10px;

  h2 {
    font-size: 1.7rem !important;
  }
}

#confirmation-dialog-title {
  height: 60px;

  h2 {
    font-size: 1.5rem !important;
  }
}

.dialog-title {
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.dialog-content {
  margin-left: 10px;
}

.margin-left-1em {
  margin-left: 1em;
}


.background-color-red {
   color:red;
}

.background-color-green {
   color:green;
}
.color-red {
  color: red;
}

.color-green {
  color: green;
}

.color-blue {
  color: blue;
}

.margin-top-large {
  margin-top: 5vh;
}

.margin-top-10 {
  margin-top: 10px;
}

.font-standard {
  font-size: 1rem;
}

.font-medium {
  font-size: 1.2rem;
}