
.container {
  padding: 0px !important;
}

.main {
  height: 100%;
}

.col-centered{
  float: none;
  margin:  auto;
  }

.home{
  background: url("/Content/images/home_page/header/sketchbook.jpg") center / cover no-repeat;
  justify-content: end;
}

//Header

.home-header {
  background-color: $site-background-color !important;
  border: 0 !important;
  border-radius: 20 !important;
  opacity: 1;
}

.home-header-content {
  color: $site-main-color;
  font-size: $text-lg;
  font-family: $title-font;
  text-align: center;
}

#company_logo{
  width: 50%;
}

#company_name {
  font-size: $text-lg;
  font-weight: bold;
  color: $site-main-color;
}

#company_phrase {
  font-style: italic;
  font-size: $text-m;
}

#company_subtitle {
  font-size: $text-md;
}

.home-header-btn {
  background-color: $site-green-color;
  color: $element-color;
  font-size: $text-m;
  font-family: $tech-font;
  text-align: center;
  border-radius: 10px;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    background-color: $hover-color;
    color: $element-color;
    cursor: pointer;
  }
}

//Tjenester

.home-function-row {
  background-color: $site-main-color;
}

.home-function-content-row{
  padding-top: 60px;
  padding-bottom: 40px;
  justify-content: center;
}

.home-row-title {
  margin-top: 50px;
  margin-left: 40px;
  color: $site-light-color;
  font-size: $text-l;
  font-family: $title-font;
  text-align: start;
  text-shadow: 3px 0px $hover-color;

  
}

  #light{
    color: $site-main-color !important;
    margin-top: 25px !important;
    text-shadow: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold;
  }

.home-function-icon {
  color: $site-light-color;
  filter:drop-shadow(5px 5px 0px $hover-color)
}
.home-function-text {
  color: $site-light-color;
  font-size: $text-md;
  font-family: $base-font;
  text-align: center;
  font-weight: 200;
  margin-top: 20px;
  font-style: italic;
  text-shadow: 3px 0px $hover-color;
}

// Priser

.home-price-container {
  background-color: $site-green-color;
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 40px;
}

.home-price-card {
  border-radius: 10px;
  background-color: white;
  display: grid;
  grid-template-rows: 0.5fr 1fr 0.7fr auto;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 20px;
}


.home-price-button {
  background-color: $site-green-color;
  color: $element-color;
  font-size: $text-sm;
  font-family: $title-font;
  text-align: center;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none !important;
  // width: 75%;

  &:hover {
    background-color: $hover-color;
    color: $element-color;
    cursor: pointer;
  }
}



.home-nav {
  background-color: $element-color !important;
}

.home-custom-button {
  outline: 0 !important;
  text-align: center;
  font-size: $text-lg !important;
  font-family:$title-font;
  color: $site-main-color !important;
  border-radius: 0 !important;
  background-color: $element-color !important;

  &:hover{
    background-color: #545d61!important;
    color: $element-color !important;
  }

}


.home-info-tab {
  background:  url("/Content/images/home_page/header/sketch.png") center / cover no-repeat; 
}

.home-info-card {
  background-color: $element-color !important;
  border-radius: 0 !important;
  border: 0 !important;
 }

.home-info-icon {
  color: $site-main-color;
  text-align: center !important;
}

.home-info-text{
  color: $site-main-color;
  font-size: $text-s;
  font-family:$title-font;
  text-align: start;  
}

#home-info-text-lg{
  font-size: $text-xl;
  color: $hover-color; 
}

#home-info-text-md{
  font-size: $text-m;
  text-align: start;
  font-family: $base-font !important;
  
}

#home-info-text-title{
  font-size: $text-md;
}

#home-small-text{
  font-size: $text-xs;
}

#report {
font-size: $text-m;
}


hr {
  border-color: $site-main-color !important;
  color: $site-main-color !important;
  opacity: 0.5 !important;
  border-top: 2px solid !important;
}




//Customer review
hr.blue{
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  border-top: 4px solid #44758C !important;
  opacity: 10 !important;
  margin-right: 20px;
  width: 250px;
}
hr.green{
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  border-top: 4px solid #7DA868 !important;
  opacity: 10 !important;
   margin-left: 20px;
   width: 250px;
}

.home-customer-row{
  background-color: #545d61;
}
.home-customer-card{
  padding: 20px;
}

.home-customer-text {
  color: $site-main-color;
  font-size: $text-lg;
  font-family: $base-font;
  font-style: italic;
  text-align: center;
}
.home-customer-name {
  color: $hover-color;
  font-size: $text-lg;
  font-family: $title-font;
  font-weight: bold;
  text-align: center;
}

.home-customer-icon {
  text-align: center;
}





//Cards

.home-card {
 background-color: $element-color !important;
 border-radius: 20 !important;
 border: 0 !important;
 height: 90% !important;
}

#icon{
  color: $site-main-color;
  filter:drop-shadow(5px 5px 0px $hover-color)
}

.home-card-title {
  color: $site-main-color;
  font-size: $text-md;
  font-family: $title-font;
  font-weight: bold;
  text-align: center;
}

.home-card-content {
  text-align: center;
  color: $hover-color;
  font-size: $text-sm;
  font-family: $base-font;
}


// About 

.home-about-row {
  background:  url("/Content/images/home_page/header/sketch.png") center / cover no-repeat; 
}

.home-card-padding {
  padding-top: 0 !important;
}

.home-info-about-text-main {
  color: $site-main-color;
  font-family:$base-font;
  font-size: $text-lg;
  text-align: center;
}

.home-info-about-text-title {
  color: $site-main-color;
  font-family:$base-font;
  font-size: $text-l;
  font-weight: bold;
  text-align: center;
}



