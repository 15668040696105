@import '../../../assets/style/scss/base/variables.scss';
@import '../../../assets/style/scss/base/byggekost.scss';


.section-header {
   font-size: 1.4rem;
   font-weight: bold;
   color:$site-black-color;
   font-family:$report-font;
   background: $site-light-color;
   padding-left:15px;
   padding-bottom: 5px;
   display:flex;
   height:33px;
   padding-top:0;
   border-radius: 5px 5px 0 0;
   width: 100%;
   margin-top: 20px;

   .description {
      flex:15;
   }

   .description-mini {
      flex:2;
   }

   .description-medium {
      flex:auto;
   }

   .action-menu {
      text-align: right;
      flex:1;
      margin-left:10px;
      padding-top: 2px;
      padding-right:46px;
      cursor:pointer;
      min-width:150px;

   
      svg {
         float:right;
         margin-top:6px;
         font-size: 1rem;
         margin-left:20px;
         color:$site-black-color;
      }
   }

   .input-field {
      flex:13;
      display:flex;
      margin-top: 5px;
      
      input {
         max-width:250px;
         padding-left:6px;
         padding-bottom:2px;
         padding-top:2px;
         background-color: $element-color;
         color:black;
         font-size:1rem;
         outline: none !important;
         border: none !important;
         box-shadow: none!important;
      }

      input:focus {
         box-shadow: none !important;
      }

      input::placeholder {/* Chrome, Firefox, Opera, Safari 10.1+ */
         color: $placeholder-color;
         opacity: 1;/* Firefox */
      }
      input:-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: $placeholder-color;
       }
       
       input::-ms-input-placeholder { /* Microsoft Edge */
         color: $placeholder-color;
       }
   }

   .search-icon {
      margin-top:3px;
      margin-right:5px;
      min-width:10px;
   }

}