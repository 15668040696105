/*.viewreport-container {
   display: flex;
   flex-direction: row;
 }*/

 
 .report-content{
   background-color: $element-color;
   padding-top: 3px;
   padding-bottom: 15px;
   padding-left: 10px;
   padding-right: 10px;
   border-radius: 10px;

 }

.viewreport-infobox{

   
   border:2px solid $site-main-color;
   padding:1vw;

   .report-info-header{
      color: $site-main-color;
      font-size: $text-sm;
      text-align: center;
      font-family: $title-font;
   }

   .report-info-address{
      color: $site-main-color;
      font-size: $text-sm;
      text-align: center;
      font-family: $report-font;
   }

   .report-info-data{
      color: $site-main-color;
      font-size: $text-s;
      text-align: center;
      font-family: $report-font;
   }

   .report-info-button{
   }
}

.viewreport-frontpage {
   border:2px solid $site-main-color;
   padding:1vw;

   .report-first-header {
      width:100%;
      margin-top: 10px;
      margin-bottom: 30px;
      justify-content: end;
   }
   .report-second-header {
      color: $hover-color;
      font-size: $text-m;
      text-align: center;
      font-family: $title-font; 
      font-weight: bold;   
   }

   /*.report-third-header {
      margin-bottom:10px;
      width:100%;
      padding-top:6px;
      padding-bottom:6px;
      font-size:120%;
      background-color: $bk-header-color;
      color:white;
      text-align:center;
   }*/

   .report-main-picture {
      height:40%;
      text-align:center;
      img {
         max-width:100%;
         max-height:100%;
      }
   }


   .report-banner {

      .banner-container {
         padding:3%;
         
      }

      

      .report-address-smaller{
         font-family: $title-font;
         font-size: 12px;
         color:$hover-color !important;
      }

      /*.date-container {
         text-align: center;
      }*/

     
   }

   .report-address {
      font-family: $title-font !important;
      font-size: $text-s !important;
      color: $hover-color !important;
      text-align: center !important;
   }
   .report-zip {
      font-family: $title-font !important;
      font-size: $text-xs !important;
      color: $hover-color !important;
      text-align: center !important;
   }  
   .report-nr {
      font-family: $title-font !important;
      font-size: $text-xxs !important;
      color: $hover-color !important;
      text-align: center !important;
   }

   .date-box{
      font-size:100% !important;
      color:$bk-header-color;
      text-align: center;
      font-weight: 100;
      text-align: end;
   }

   .date-box-title{
      font-size:60% !important;
      color:$bk-header-color;
      text-align: center;
      font-weight: bold;
      text-align: end;
   }

   .user-box{
      font-size:60%;
      color:$hover-color;
   }

   .company-logo {
      img {
         max-width: 90%;
      }
   }

   .company-box{
      font-size:50%;
      color:$hover-color;
   }

   .company-box-big{
      font-size:75%;
      font-weight:bold;
      color:$hover-color;
   }

   .report-company {
      /*width:100%;
      height:25%;
      padding-top:14%;
      text-align:left;
      padding-bottom: 100px;*/

     

      

      

     
   }

   
}

.report-footer {
   //height:100%;
   justify-content: end;
}


.report-icon-printer {
   float:right;
   cursor:pointer;
   margin-right:20px;
   margin-top:4px;
   width:50px;
 }
 

 .report-status-button{
   background-color: #D1E0E1;
   color: #334652;
   font-weight: bold;
   font-size: 18px;
   color: #44758C;
   margin-top: 4px;
   margin-right:10px;
   padding-top: 5px;
   padding-left:30px;
   padding-right:20px;
   min-width: 200px;
   height: 40px;
   cursor:pointer;
   border:1px solid white;
   display:flex;
 
   
   &:hover {
     background-color:rgba(106,157,158,1);
     color:#D1E0E1;
   }
 }

 .report-status-icon {
   flex:1;
   height: 24px;
   width: 24px;   
   max-height: 24px;
   max-width: 24px;
   border-radius: 50%;
   display: inline-block;
   margin-left: 4px;
   margin-top:0px;
   cursor:pointer;
 }

 .report-dialog{
   min-height:200px;
   margin-left: 75px;
   margin-right: 75px;
 }

.report-dialog-button{
   background-color:  $site-main-color;
   font-family: $title-font !important;
   font-size: $text-sm;
   color: $element-color;
   border-radius: 10px;
   cursor:pointer;

   &:hover {
      background-color: $hover-color !important;
      color: #F1F4F5 !important;
    }
}

.report-dialog-QR-img{
   margin-top: 15px;
   margin-bottom: 15px;
}

 .report-dialog-text{
   text-align: center !important;
 }

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
   .viewreport-container {
     flex-direction: column;
   }

   .viewreport-frontpage .report-banner .report-address {
      font-size:$text-s;
   }
   .viewreport-frontpage .report-banner .report-address-smaller {
      font-size:$text-xxs;
   }
   .viewreport-frontpage .report-banner .date-container {
      padding-top:8%;
   }
   .viewreport-frontpage .report-banner .date-box {
      font-size: $text-xs;
   }


   .viewreport-frontpage .report-company {
   }
   .viewreport-frontpage .report-company .company-box {
      font-size:10px
   }
   .viewreport-frontpage .report-company .user-box {
      font-size:$text-xs;
      text-align:center
   }

 }


