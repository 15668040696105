.profile-module-row {
   display: flex;
   width: 100%;
   margin-bottom:6px;
   font-size: 1rem;
   margin-top:10px;

   .picture-div {
      padding-left:20px;
   }

   div {
      textarea {
         resize: vertical;
         padding-bottom:8px;
      } 
      
      img {
         
         max-height: 40vh; 
         width: 100%; 
         object-fit: contain;
         vertical-align: top;
       }
  }
}

.profile-module-picture-row {
   display:flex;
   flex-wrap: wrap;
   width: 100%;
   margin-bottom:6px;
   font-size: 1rem;
   margin-top:10px;


   .about-module-picture-row-frame {
     width:31%;
     padding-right:10px;

      textarea {
         resize: vertical;
      }   
  }
}

.no-image-text{
   color:$hover-color;
   text-align:center;
   font-size: 1.5rem;
   padding-top: 10px;
   padding-bottom: 10px;
   background-color: $site-light-color;
   vertical-align: middle;

   &:hover{
      background-color:$hover-color;
      color: $element-color;
      cursor: pointer;
   }
}


.profile-box{
   text-align: center;
   height:7vw;
   position: relative;
   margin-bottom:0;

   .profile-picture{
      height:100%;
   }

}