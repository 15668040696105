.statistics-header {
   font-size:1.8rem;
   font-weight:bold;
   border-bottom: 2px solid black;
   text-align: start;
}

.statistics-box {
   width:100%;
   margin-bottom: 30px;

   .statistics-column{
      flex:1;
      display:flex;
      align-items: center;
      margin-left:20px;
      margin-right:20px;

      .statistics-column-header-big {
         font-weight:bold;
         font-size:0.9rem;
      }
      .statistics-column-header {
         font-weight:bold;
         font-size:0.9rem;
         text-align: center;
      }

      .statistics-column-name {
         font-size:1rem;
      }
      .statistics-column-data {
         text-align:center;
         font-size:1rem;
      }
      
     
   }

   .statistics-grey {
      background-color: #d1d8e1;
      border-top: 1px solid #CBCBCB;
    }
    
    .statistics-white {
      background-color: white;
      border-top: 1px solid #CBCBCB;
    }

    .statistics-sum-row{
      background-color:$site-main-color;
      color: $element-color;
      font-size: $text-sm;
      font-weight: bold;
    }
}