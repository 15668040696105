.contact-dialog-frame {
   position: fixed;
   right:2vw;
   bottom: 5vh;
   z-index: 999;
}

#dialog-hidden {
   display: none;
}

.contact-dialog-collapsed {
   float: right;
   height: 60px;
   width: 60px;
   background-color: #44758C;
   border-radius: 50%;
   cursor: pointer;
   padding-top:6px;
   text-align: center;

   i {
      color: white;
      font-size: 32px;
   }

   &:hover {
      background-color: #2f5a6e;
    }
}

.contact-dialog-box {
   position: fixed;
   right:2vw;
   bottom: 13vh;
   z-index: 999;
   width:320px;
   height:480px;
   border-radius: $site-border-radius;
   background-color: white;
   transition: visibility 0s, opacity 0.5s linear;
   outline: none;
}


.contact-dialog-box-header{
   color:white;
   background-color: #44758C;
   border-radius: $site-border-radius $site-border-radius 0 0;
   padding:10px;
   font-family: $title-font;
   
   i {
      color: white;
      font-size: 16px;  
   }

   svg:first-child {
      margin-right: 10px;
   }
   svg:last-child {
      margin-top:4px;
      float:right;
      cursor:pointer;
   }
}

.contact-dialog-box-content{
   padding:20px;
   font-family: $base-font;

   div {
      margin-bottom:10px;
   }
}
.contact-dialog-box-form{
   width:100%;
}

.contact-dialog-date-picker{
   flex:1;
   display: flex;
   margin-left:30px;
   margin-bottom:10px;
   align-items:baseline;
   gap: 0.5rem;
}