.area-specification-building-section{
   margin-bottom:30px;
}

.area-specification-building-section:last-of-type {
   margin-bottom:0px;
}

.area-plus-btn{
   cursor: pointer;
}


.area-specification-floor-row {
   display: flex;
   // width: calc(100% - 40px);
  // margin-bottom:2px;
   font-size: 1rem;

   .area-spec-header {
      // font-weight:bold;
      padding-right:10px;
      margin-top:4px;
   }


   .placeholder-vertical-padding{
      // padding-top:8px;
   }

   .input-container {
      width:100%;
      padding-right:4px;
      position: relative;

      input {
         padding-right:22px;
         padding-top:2px;
         padding-bottom:2px;
         margin-top:2px;
      }

      .disabled {
         // border:1px solid rgba(118, 118, 118, 0.3);
         border:1px solid #777;
         //padding-top:2px !important;
         //padding-bottom:3px !important;
         border-radius:2px;
         padding-left:6px;
         //background-color:rgba(239, 239, 239, 0.3);
         color:black;
      }


      .sum {
         background-color:$site-light-color;
         
      }

      .totalsum{
         background-color:$site-light-color;  
         
      
      }

      .input-numeric {
         text-align:right;
         margin-top:2px;
         padding-top:3px;
         padding-bottom:2px;
      }

      i {
         font-size: 0.9rem;
         right: 4px; 
         position: absolute;
         transform: translate(0, -50%);
         top: 10px;
         // margin-top:1px;
         width: 25px;
         text-align: center;
         font-style: normal;
         color:black;
       }


   }



   input {
      width:100%;
      font-size:0.8rem;
   }

   .line-height-80 {
      line-height: 100%;
   }

   .area-specification-floor-header {
      padding-left:4px;    
      font-size: 0.8rem; 
   }

   .placeholder-padding {
      margin-left:30px;
   }
}




.area-specification-floor-delete-icon{
   width:20px;
   color: #f55142;
   margin-left:10px;
   font-size:1rem;
   cursor: pointer;
}

.area-specification-floor-description{
   font-size:0.8rem !important;
   padding-top:4px !important;
   padding-bottom:3px !important;
   margin-top:2px !important;
   border:1px solid rgb(118, 118, 118) !important;
   border-radius:2px;
}

.area-specification-subtitle{
   font-size: 1rem;
   font-weight: bold;
}

.area-specification-table-header {
   height:30px;
}
.area-spec-floor-name{
   font-size:0.8rem;
   padding-left:20px;
}