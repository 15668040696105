.technical-value-header {
   display:flex;
   margin-top:20px;
   // font-weight: bold;
   font-size: 1rem;
   // margin-left:10px;
   // margin-right:10px;
   
   .tech-header {
      
      
      div {
         padding-left:20px;
         padding-right:0px;
      }

      .vertical {
         padding-top:10px;
         display: table-cell; 
         vertical-align: middle;
      }
   }
}

.technical-value-menu{
   width:40px;     
   padding-left:20px;
   margin-top:2px;
   cursor:pointer;
}

.technical-value-building {
   display:flex;
   margin-top:2px;
   font-size: 1rem;
   position: relative;

   .tech-header {
      font-size: 0.9rem;
      padding-left:10px;
   }

   .margin-left{
      margin-left:20px;
   }

   .input-frame {
      position: relative;
      text-align: right;

      .building {
         text-align: left;
         border:1px solid rgba(118, 118, 118, 0.3);
         border-radius:2px;
         padding-left:6px;
         background-color:rgba(239, 239, 239, 0.3);
      }

      input {
         margin-left:20px;
         font-size:1rem;
         padding-right:20px;
         text-align: right;
         width:calc(100% - 20px);


      }
      .input-name {
         margin-left:0;
         text-align:left;
         width:100%;
         padding-left:6px;
      }

      i {
         right: 5px; 
         position: absolute;
         transform: translate(0, -50%);
         top: 50%;
         width: 25px;
         text-align: center;
         font-style: normal;
      }


   }
}


.technical-value-sum {
   display:flex;
   margin-top:8px;
   font-size: 1rem;
   font-weight: bold;
   // margin-left:10px;
   // margin-right:10px;
   position: relative;


   .input-frame {
      position: relative;
      text-align: right;

      input {
         border:2px solid #777;
         margin-left:20px;
         font-size:1rem;
         padding-right:20px;
         text-align: right;
         width:calc(100% - 20px);
      }

      i {
         right: 5px; 
         position: absolute;
         transform: translate(0, -50%);
         top: 50%;
         width: 25px;
         text-align: center;
         font-style: normal;
       }
   }
}

.simple-tech-header {
   padding-left:40px;
}