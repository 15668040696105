@import '../../../assets/style/scss/base/variables.scss';
@import '../../../assets/style/scss/base/byggekost.scss';


.category-header-module {
   font-size: 1.2rem;
   font-weight: bold;
   font-family:$report-font;
   color:$site-black-color;
   background: #E6E7E8;
   padding-left:30px;
   border-radius: $site-border-radius $site-border-radius 0px 0px;
   display:flex;
   height:28px;
   padding-top:2px;
   margin-top: 15px;
}

.category-header-module-blank{
   font-size: 1.2rem;
   font-weight: bold;
   font-family:$report-font;
   color:$site-black-color;
   display:flex;
   height:28px;
   padding-top:2px;
   margin-top: 15px;

   input {
      margin-left:0 !important;
   }
}

.description {
   flex:13;

}
