.select-layout-frame {
   padding-left:10%;
   padding-right:10%;
}

.select-layout-button {
   flex:1;
   text-align:center;

   div{
      width:200px;
      height:200px;
      background-color: $bk-green-light;
      margin:auto;
      border-radius:50%;
      padding-top: 73px;
      color:$bk-header-color;
      font-size: 32px;
      font-weight: bold;
      cursor:pointer;
      border:1px solid $bk-green-dark;
   }

   :hover {
      background-color: $bk-green;
   }
}

.select-layout-text {
   text-align:center;
   font-size: 1.4rem;
}