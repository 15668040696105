@import 'src/assets/style/scss/base/variables.scss';

.navbarbuttonsp {
    font-size: $text-sm;
    padding-left: 15px;
    padding-right: 15px;
    color: $element-color;
    padding-top: 1px;
    padding-bottom: 1px;
    background-color: $site-green-color;
    border-radius: 10px;
    font-family: $tech-font;
  
    &:hover {
      background-color: $hover-color;
    }

   
  
  } 

  @media only screen and (max-width: 768px) { 
      .navbarbuttonsp{
        color: $site-background-color;
        background-color: $site-green-color;
        text-align: center;
        font-size: 25px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
        
    }