.signature-frame {

   margin-top:20px;
   text-align:center;
   min-height:40px;
   vertical-align: bottom;

   .date-box {

      margin-left:10%;
      font-size:1.2rem;
      text-align:center;
      border-bottom:1px solid black;
      max-width:80%;
      height:100%;
      position: relative;
      .bottom {
         position: absolute;
         bottom: 0;
         width:100%;
      }

      .date-input {
         padding-right:10px;
         margin-bottom:4px;
         input {
            margin-left:30%;
            width:70%;
            font-size:1.2rem;
         }
      }


   }

   .signature-box{
      border-bottom:1px solid black;
      margin-left:10%;

      text-align: center;
      height:7vw;
      position: relative;
      margin-bottom:0;


      .signature-module-signature-picture{
         width:60%;
         height:100%;
         margin:auto;
         padding-bottom:4px;
      }

      .signature-module-signature-text{
         width:60%;
         margin:auto;
      }
   }

}

.signature-text {
   text-align:center;
   font-size: 0.8rem;
   //padding-left:5%;

   input {
      width:40%;
      margin:auto;
      font-size: 0.8rem;
   }
}




// .signature-place-input{
//    min-width: auto;
//    width:30%;
//    padding-right:24px;
//    font-size:1.3rem;
//    color:rgb(74, 74, 74);
// }

.signature-name-input{
   min-width: auto;
   width:40%;
   text-transform: uppercase;
   padding-right:24px;
   font-size:0.9rem;
   color:rgb(74, 74, 74);
}
