.picture-row{
  display:flex;
  width: 100%;
}

.picture-frame {
  margin-left:20px;
  height:7vw;
  width:calc(100% - 20px);
  object-fit:contain;
}

.picture-text{
  margin-left:20px;
  margin-top:10px;
  width:calc(100% - 20px);
}
