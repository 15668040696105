.upload-image-frame {
  height:100%;
  width:100%;
  cursor: pointer;
  text-align: center;
  border:1px solid #aaa;
  border-radius:3px;
  
  .upload-image-img {
    width: 100%; 
    height:100%;
    object-fit: scale-down;
    vertical-align: top;
    margin:auto;
    overflow: hidden;
  }   
}


.upload-image-frame-no-image{
  position: relative;
  width:100%;
  height:100%;
  cursor:pointer;
  border:1px solid rgb(170,170,170);

  .upload-image-no-image {
    position: relative;
     width:100%;
     height:100%;
     background: 
            linear-gradient(to top left,
                rgba(170,170,170,0) 0%,
                rgba(170,170,170,0) calc(50% - 0.8px),
                rgba(170,170,170,1) 50%,
                rgba(170,170,170,0) calc(50% + 0.8px),
                rgba(170,170,170,0) 100%),
            linear-gradient(to top right,
                rgba(170,170,170,0) 0%,
                rgba(170,170,170,0) calc(50% - 0.8px),
                rgba(170,170,170,1) 50%,
                rgba(170,170,170,0) calc(50% + 0.8px),
                rgba(170,170,170,0) 100%);
     color:$bk-green-dark;
     text-align:center;
     font-size: 24px;

     div{
        position: relative;
        top:calc(50% - 22px);       
      }
   }

}
