.module-content-row-declaration{
    display: flex;
    gap: 2rem;
    align-items: center;
}
.module-content-column-declaration{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
}

.labeled-check-btn{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.liability-input-field{
    height: 1.5rem;
}

.area-content-grid-declaration{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 3fr;
    gap: 1rem;
    align-items: center;
    margin: 0 1rem 0 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(210, 210, 210);

   .area-column-declaration{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-weight: bold;
        font-size: small;
   }
}

.regulation-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    font-family: $title-font;
    font-weight: bold;
    padding: 1rem;
    width: 85%;
}

.application-list{
    display: flex;
    flex-direction: column;
    font-family: $title-font;
    gap: 0.5rem;
    font-weight: bold;
    font-size: small;
    background-color: rgb(229, 231, 231);
    padding: 1rem 2rem 1rem 2rem ;
}