
.login-container {
    background-color: $element-color !important;
}

.login-text {
    color: $site-main-color;
    font-size: $text-s;
    font-family: $base-font !important;
    
}

.login-title {
    font-size: $text-s;
    font-family: $title-font;
    color: $site-main-color;
}

.login-field {
    width: 100% !important;
    border-color: $site-main-color !important;
}

#login-forgot {
    display: block;
    text-align: end;
    color: $site-main-color;
    font-size: $text-s;
    font-family: $base-font;

    &:hover {
        color:$hover-color;
        cursor: pointer;
    }
}

.login-subtitle {
    color: $site-main-color;
    font-size: $text-s;
    font-family: $base-font; 
}

.login-link {
  font-size: $text-s;
  font-family: $base-font;
  color: $site-green-color;
  text-decoration: underline;

  &:hover {
    color:$hover-color;
    cursor: pointer;
  }
}

.login-button{
    
  background-color: $site-green-color;
  color: $element-color;
  font-size: $text-sm;
  font-family: $tech-font;
  text-align: center;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;

  &:hover {
    background-color: $hover-color;
    color: $element-color;
    cursor: pointer;
  }
}