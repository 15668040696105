
.leasehold-section {
   margin-left:2rem;
   margin-bottom:4rem;

   .header {
      font-size: 1.2rem;
      font-weight:bold;
   }

   .text {
      font-size: 1rem;
      margin-left:1rem;
      margin-top:4px;
   }
}

.leasehold-input {
   width: 100% !important;
   
}

.leasehold-input-year {
   text-align: right !important;
   
}

.leasehold-row {
   display:flex;
   font-size: 1rem;
   padding-top:8px;

  

   .leasehold-row-big {
      text-align:center;
      flex:8;
   }

   .leasehold-row-small {
      text-align:center;
      flex:1;
   }



   .align-right {
      text-align: right;
      padding-right:3rem;
   }
}

.leasehold-left-margin {
   margin-left:1rem;
}

.leasehold-top-margin {
   margin-top:1rem;
}


.leasehold-small-table {
   max-width:40vw;
}

.leasehold-control-small{
   max-width:8vw;
   min-width:8vw;
   font-size:1rem;
   margin-left:10px;
}

.leasehold-control-large{
   max-width:20vw;
   font-size:1rem;
}

.leasehold-edit-comment{
   margin-left: 40px;
  display: inline-block;
  margin-top: 4px;
 }

 .leasehold-edit-comment-input {
   font-size: 16px;
  //float: right;
  width: 300px;
  margin-top: 11px;
  //margin-bottom: 20px;
  margin-left: 10px;
  height: 30px;
 }

