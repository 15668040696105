.tg-box-row {
   display:flex;
   min-height:5vw;
   margin-bottom:6px;
   padding-left:0;
   padding-right:0;
   // max-width:50vw;
}

.tg-box-button {
   flex:2;
   border:1px solid #aaa;
   text-align: center;
   font-weight: bold;
   color:white;
   font-size: 1.4rem;
   -webkit-text-stroke-width: 1px;
   -webkit-text-stroke-color: black;

   display: flex;
   justify-content: center;
   align-content: center;
   flex-direction: column;
}

.tg-box-button-big {

   font-size: 2.2rem;
}

.tg-box-description {
   flex:3;
   padding:4px;
   padding-left:15px;
   padding-right:15px;
   border:1px solid #aaa;
   margin-right:6px;
   margin-left:6px;
   text-align: center;
   background-color: #eee;
}

.tg-box-details {
   flex:10;
   padding:4px;
   border:1px solid #aaa;
   background-color: #eee;
   ul {
      margin-left:20px;
      list-style-type:disc;
   }
}

