@import '../../../assets/style/scss/base/variables.scss';
@import '../../../assets/style/scss/base/byggekost.scss';


.section-header-module {
   font-size: 1.4rem;
   font-weight: bold;
   color:$site-black-color;
   font-family:$report-font;
   background: $site-light-color;
   padding-left:25px;
   padding-bottom: 5px;
   display:flex;
   height:33px;
   padding-top:0;
   border-radius:5px 5px 0 0 ;
   width: 100%;
   margin-top: 20px;

}

.action-menu {
   text-align: right;
   flex:1;
   margin-left:10px;
   padding-top: 5px;
   padding-right:46px;
   cursor:pointer;
   min-width:150px;


   svg {
      float:right;
      margin-top:6px;
      font-size: 1rem;
      margin-left:20px;
      color:black;
   }
}