@import 'src/assets/style/scss/base/variables.scss';

.bkbutton { 
  background-color: $site-green-color;
  font-family: $tech-font;
  font-size: $text-m;
  color: $element-color;
  max-height: 40px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  overflow: hidden;

  cursor: pointer;
  border-radius: 10px;

  &:hover {
    background-color: $hover-color!important;
    color: $element-color !important;
  }
  
}

.bkbutton-disabled {
  background-color: $site-grey-color !important;
  cursor: auto !important;
}