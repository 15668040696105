.contact-container {
  background-color: #F1F4F5 !important;
}

.contact-title {
  color: $site-main-color;
  font-size: 18px;
  font-family: $title-font;
}

.contact-content {
  font-family: $base-font !important;
}

.contact-button {
  text-align: end;
}

.contact-text {
  font-family: $base-font;
  color: $site-main-color;
  font-size: $text-sm;
}
