@import 'src/assets/style/scss/base/variables.scss';

.contactbutton {
  background-color: $site-main-color;
  color: $element-color;
  font-size: $text-m;
  font-family: $tech-font;
  text-align: center;
  border-radius: 10px;
  text-decoration: none;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    background-color: $hover-color;
    color: $element-color;
    cursor: pointer;
  }
  
  }