.description-title {
  font-size: 1.2rem;
  font-weight: bold;
  padding-bottom: 10px;
}

.description-input {
  display: flex;
  margin-left: 10px;
  max-width: 250px;
  font-size: 1.2rem;
}


.description-category-section {
  margin-top: 20px;
  margin-bottom: 20px;

  .title {
    font-size: 1.2rem;
    margin-left: 20px;
    color: black;
    margin-bottom: 2px;
    font-weight: bold;
  }


}