.content-row-declaration{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
    font-size:1.2rem;
}

.pdf-icon{
    color:red;
    cursor: pointer; 
}

.download-icon{
    color:black;
    cursor: pointer;
    font-size:1rem;
}

.pdf-link{
   //color: $hover-color;
   //text-decoration: underline;
   cursor: pointer;  
}

.PDF_delete{
    cursor: pointer; 
    color: red;
}