
.approval-mark-picture{
   margin-bottom:10px;
   margin-top:10px;

   .upload-image-frame {
     
      .upload-image-img {
        height: 144px; 
      }       
   }

   .upload-image-frame-no-image{

      .upload-image-no-image {
        height: 144px; 
      } 
   }

   .text {
      margin-top:10px;
      margin-left:0 !important;
      margin-right:0 !important;
      width:100% !important;
      text-align: center;
   }
}
