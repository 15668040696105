﻿@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orienta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@800&display=swap');
//Fonts

$title-font: 'Noto Sans', sans-serif;
$base-font: 'Roboto Condensed', sans-serif;
$tech-font: 'Roboto Condensed', sans-serif;
$report-font: 'Arial';

//Main colors

$site-main-color:#44758C;
$site-background-color: #e6e7e8;
$element-color: #F1F4F5;
$hover-color:#294856;
$site-dgrey-color: #7E7E7E;
$site-green-color: #7DA868;
$site-light-color: #c2d7e8;
$site-grey-color: #d1d8e1;
$site-grey-light-color: #e0e5eb;
$placeholder-color:#AAA;
$site-black-color:#354753;
$site-comment-color:#E6E7E8;

//#a0c2de - table header blue

//Font-size

$text-xl: 60px;
$text-l: 40px;
$text-lg: 30px;
$text-md: 25px;
$text-m: 23px;
$text-sm: 20px;
$text-s: 18px;
$text-xs: 17px;
$text-xxs: 13px;


$site-border-radius: 5px;


$bk-green:rgba(135,176,177,1);
$bk-green-dark:rgba(106,157,158,1);
$bk-green-light:rgba(212,228,228,1);



$bk-background: transparent;
$bk-main-color:rgba(106,157,158,1);
$bk-header-color:#354753;
$bk-title-color:#44758C;

$bk-light-blue:#c2d6e5;;
$bk-medium-blue:#99b7cc;

$bk-light-green:#d5e5e7;
$bk-medium-green:#a6cbc9;

$bk-light-blue:#99b7cc;

$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1200px;
$screen-xl: 1600px;

// /* BULMA COLOR OVERRIDES */
 //$red: #e93e60;
 $cyan: #447379;
 //$blue:  #447379;

// /* COLOR */
// $color-body-bg: #f7f7f7;
// $color-nav-bar: #33363b;
// $color-hero-is-dark: #1f2227;
// $color-blue-highlight: $cyan;

/* REACT-TOASTIFY OVERRIDES */
$rt-toast-min-height: 78px;
$rt-toast-border-radius: 3px;
$rt-color-info: #3298dc;
$rt-color-error: #e93e60;

/* MIXINS */
@mixin renderTabletNavView {
  @media only screen and (max-width: 950px) and (min-width: 600px) {
    @content;
  }
}

@mixin renderMobileNavView {
  @media only screen and (max-width: 599px) {
    @content;
  }
}

@mixin removeNavBarPadding {
  @media only screen and (max-width: 1099px) {
    @content;
  }
}

@mixin reduceNavBarPadding {
  @media only screen and (max-width: 1472px) and (min-width: 1100px) {
    @content;
  }
}
