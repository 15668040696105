.document-link-container{
   //text-align: center;
   padding-top:20px;
   margin-left:20%;
   margin-right:20%;
//   width:40vw;
   color:$bk-main-color;
}

.dashboard-btn {
   display: flex;
   background-color: $site-main-color;
   width: 100%;
   border-radius: 20px;
   font-size: $text-lg;
   font-family: $base-font !important;
   color: $element-color;
   padding: 1rem 2rem;
   align-items: center;
   min-height: 6rem;
   gap: 2rem;
   cursor: pointer;

   &:hover {
      background-color: $hover-color;
   }
   
}



#dashboard-link-btn{
   text-align: start !important;
   padding-bottom: 10px !important;
}

.dashboard-link-icon{
   vertical-align: -0.25em !important;
}

.dashboard-link {
   font-size: $text-sm !important;
   text-align: start !important;
   font-family: $base-font !important;
   color: $element-color !important;
   text-decoration: none !important;
   padding: 0;
}

.dashboard-btn-text{
   font-size: $text-sm !important;
   font-family: $base-font !important;
   color: $element-color !important;
   text-decoration: none !important;
}

.dashboard-back-btn {
   font-size: $text-lg !important;
   font-family: $base-font !important;
   color: $site-main-color !important;
   text-decoration: none !important;

   &:hover {
      color: $hover-color !important;

   }
}

#bygge-btn{
   width: 4rem;
   height: auto;
}


.document-link {
   cursor:pointer;
   font-size: 22px;
   margin-bottom:10px;
}

.document {
   margin-top:20px;
   padding-left:10px;

   ol {
      padding-left:40px;
      li{
         
         ul{
            padding-left:40px;
            list-style: circle;
         }
      }
   } 

   a {
      color: #337ab7;
      text-decoration: none;
   }

   p {
      margin: 0 0 10px;
  }
}

