.introduction-header {
   font-size: 1.2rem;
   font-weight: bold;
   font-family:$report-font;
   color:black;
   background: $site-light-color;
   padding-left:0.6rem;
   padding-right:1rem;
   border-radius: $site-border-radius $site-border-radius 0px 0px;
   display:grid;
   grid-template-columns: auto 2.5fr 1.1fr 1fr 0.5fr auto;
   align-items: center;
   gap: 0.6rem;
}

.introduction-text-section {
   display: flex;
   width: 100%;
   

   .introduction-text-column {
      flex:0 0 50% ;
      overflow-y: hidden;
      padding-left:10px;
      padding-right:10px;
   }

   .introduction-text-frame{
      width:100%;
      padding-bottom:8px;
      font-size:1rem;
      .section-header {
         margin-bottom:2px;
      }

      .text {

         background-color:#E6E7E8;
         border-radius: 0 0 10px 10px;
         padding-bottom:5px;
         padding-left:10px;
         padding-right:10px;
         padding-top:3px;


         .paragraph {
            margin-top:1px;
            flex:1;
            margin-left:20px;
            font-weight: bold;
         }

         .content {
            margin-top:1px;
            flex:5;
         }
      }
   }
}

.introduction-title{
   background-color: $site-light-color;
   padding-left: 20px;
   padding-bottom: 5px;
   padding-top: 2px;
   font-weight: bold;
   border-radius: 10px 10px 0 0;
   font-family: $report-font;

}

.text-box-frame{
   padding-left:10px;
   padding-right:10px;

   .text {
      min-width:100%;
   }
}