
.area-spec-wrapper {

//   padding-right: 20px;

//   .area-spec-section{
//     margin-left:10px;
//     margin-top:50px;
// }

.area-spec-section-header {
  color: white;
  background: #354753;;
  padding-left: 10px;
  margin-bottom: 10px;
  display: flex;
  font-size: 1.2rem;

}

.area-spec-header {
    font-size: 30px;
    color: white;
    padding-left: 10px;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  
    .description {
      flex:15;
    }
    .action-menu {
      flex:1;
      margin-left:10px;
      color: $site-main-color;
      cursor: pointer;
    }
}

.area-spec-measurement-standard {
    display:flex;

    .area-spec-measurement-legend {
      color: #4a4a4a;
      //padding-left: 20px;
      flex:1;
      margin-bottom:5px;
    }
      
    .area-spec-measurement-standard-select {
      flex:10;
      padding-left:5px;
      .area-spec-control-large{
        font-family: 'Arial';
        font-size:1rem;
      }
     
    }
}

.area-spec-description-type{
    flex:12;
    padding-left:0px;
    display:flex;
    
    input {
      width:100%;
      padding:6px;
    }
  
    textarea {
      width: 98%;
      resize: vertical;
      padding:6px;
      font-size:1.2em;
    }
    
    .area-spec-description-type-text{
      flex:7;
    }
  }

  .area-spec-category-row {
    border-left:1px solid $hover-color;
    border-bottom:1px solid ;
  }
  .area-spec-category-border-r-l{
    border-left:1px solid $hover-color;
    border-right:1px solid $hover-color;
  }

  .area-spec-input{
    background:transparent !important;
    border:0;
    width:100%;
    font-size: 1em;
  }
   .area-spec-input-reduced {
    background:transparent !important;
    border:0;
    width:calc(100% - 20px);
    font-size: 1em;
    padding-top:2px;
  }
  
  .area-spec-building-input {
    margin: 0px 0px;
    padding: 5px 0px;
    display:flex;

    .area-spec-building-input-caption {
      flex:4;
      //font-size: 18px;
      font-weight: bold;
      padding-left:10px;
      padding-top:4px;
    }

    input {
      //font-size: 18px;
    }
  }

  .area-spec-building-separator {
    padding-bottom:25px;
    margin-bottom:25px;
  }

  input, textarea {
    width: 300px;
    padding-left:5px;
    height:100%;
    font-family:Arial, Helvetica, sans-serif;
  }

  textarea {
    padding-top:5px;
    resize: vertical;
  }

  .calculated-value {
    input:focus { 
      outline: none !important;
      border: none !important;
    }
  }

  .area-spec-building {
    .bkbutton {
       padding-top: 0px;
    }

    .area-spec-table-heading{
      display:flex;
    }
    .action-menu {
      color:white;
      padding-right:10px;
      .navbar-profile {
        margin-left: 20px;
      }
    }
    .area-spec-table-heading-wrapper {
      text-align: center;
      background-color: #607B91;
      border-left:1px solid  $hover-color;;
      border-bottom:1px solid $hover-color;;
      vertical-align: center;
      padding-top:2px;
    }
    .area-spec-heading-item {
      color:white;
      text-align: center;
      font-size: 18px;
    }    
    .area-spec-vertical-align {
      padding-top:14px;
    }
    .area-spec-heading-row {
      display:flex;
      border-top:1px solid  $hover-color;;
    }
    .area-spec-heading-menu{
      flex:0.5;
      padding-left:20px;
      cursor:pointer;
    }

    .area-spec-building-width-of-table {
      width: 96%;
    }
    .area-spec-building-summary {
      background-color: #607b91;
      color:white;
      font-size: 18px;
    }
    // .text-is-white * {
    //   background-color: white !important;
    // }
  }


  .area-spec-border-right {
    border-right:1px solid $hover-color;;
  }

  .area-header-margin {
    margin-top:70px;
  }

  .area-spec-is-summary {
    border:none;
    .area-spec-category-row {
      background-color: $site-grey-color;
      //padding-top:4px;
    }

    textarea, input {
      cursor: default;
      resize: vertical;
    }

    textarea:focus, input:focus{
      outline: none !important;
      border: none !important;
     
    }
  }

  .area-spec-is-total-summary {
    border:none;
    .area-spec-category-row {
      background-color: $site-light-color;
    }

    textarea, input {
      cursor: default;
      resize: vertical;
    }
  }

  .area-spec-is-total-row {
    border:none;
    .area-spec-category-row {
      background-color: $bk-light-blue;
    }

    textarea, input {
      cursor: default;
      resize: vertical;
    }
  }




  .area-spec-categoryRowInput {
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-right: 0px;
    span {
      font-size:16px;
      margin-top: 2px;
      padding-right:8px;
      padding-left:4px;
    }
  }
}



  
  



  