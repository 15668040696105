.cause-of-damage-picture-frame {
  margin-left:20px;
  height:7vw;
  width:calc(100% - 20px);
  object-fit:contain;
}

.cause-of-damage-picture-text{
  margin-left:20px;
  margin-top:10px;
  width:calc(100% - 20px);
}

.cause-of-damage-date-picker{
  margin-left:10px;
  margin-top:-6px;
  max-width:100px;
  min-width: 80%;
  margin-bottom:10px;
}

#clear-date-icon{
  cursor: pointer;
  color:#555;
  font-size:1.3rem;
}