.register-container {
  text-align: center;
  background-color: $element-color;
}



.register-control {
  width: 100% !important;
  color: $site-main-color !important;
    font-size: $text-xs !important;
    font-family: $base-font !important; 
}


.register-button {

  background-color: $site-green-color;
  color: $element-color;
  font-size: $text-m;
  font-family: $tech-font;
  text-align: center;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;

  &:hover {
    background-color: $hover-color;
    color: $element-color;
    cursor: pointer;
  }
}

@media only screen and (max-width: 575px) { 
  #register-form {
    margin-top: 15px;
  }
}

.register-title {
  color: $site-main-color;
  font-size: $text-m;
  font-family: $title-font;
  font-weight: bold;
}

.register-text {
  font-family: $base-font !important;
  color: $site-main-color !important;
  font-size: $text-m !important;
}

.register-subtext {
  font-family: $base-font;
  color: $site-main-color;
  font-size: $text-s;
}

.register-text-muted {
  font-family: $base-font;
  color: $site-main-color;
  font-size: $text-xs !important;
  text-align: end !important;
}

