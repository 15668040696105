﻿[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;

  &:before,
  &:after {
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
  }

  &:before {
    position: absolute;
    bottom: 77%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -80px;
    padding: 7px;
    width: 145px;
    border-radius: 4px;
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: white;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 13px;
    line-height: 1.5;
  }

  &:after {
    position: absolute;
    bottom: 77%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #000;
    border-top: 5px solid hsla(0, 0%, 20%, 0.9);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }

  &:hover {
    &:before,
    &:after {
      visibility: visible;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
    }
  }
}

.help-text-container {
  border:1px solid #999;
}

.helptext-list-item {
  margin-top: 5px;
  margin-left: 14px;
}

.helptext-list-item > p {
  display: list-item; /* This has to be "list-item"                                               */
  list-style-type: disc; /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
  list-style-position: inside; /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-position */
  margin:0 3px;
  text-indent: -17px;
}

.helptext-list-item-level2 {
  margin-left: 30px;
}

  .helptext-list-item-level2 > p {
      display: list-item; /* This has to be "list-item"                                               */
      list-style-type: circle; /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
      list-style-position: inside; /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-position */
      margin: 0 19px;
      text-indent: -17px;
  }


  .helptext-custom{
    font-size: 16px  !important;
    color:black !important;
    text-align:left !important;
    background-color: rgba(197,217,217,1) !important;
    max-width:600px;
    opacity: 1 !important;
    &.place-top {
      &:after {
        border-top-color: rgba(197,217,217,1) !important;
        border-top-style: solid !important;
        border-top-width: 6px !important;
      }
    }
    &.place-right {
      &:after {
        border-right-color:  rgba(197,217,217,1) !important;
        border-right-style: solid !important;
        border-right-width: 6px !important;
      }
    }
    &.place-left {
      &:after {
        border-left-color:  rgba(197,217,217,1) !important;
        border-left-style: solid !important;
        border-left-width: 6px !important;
      }
    }
    &.place-bottom {
      &:after {
        border-bottom-color:  rgba(197,217,217,1) !important;
        border-bottom-style: solid !important;
        border-bottom-width: 6px !important;
      }
    }
  }