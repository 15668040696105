
.about-flex-row{
  display: flex;
  gap: 3rem;
  align-items: center;
  padding: 0 2rem;
}

.about-container {
    background-color: $element-color !important;
    padding-top: 3px;
   padding-bottom: 15px;
   padding-left: 10px;
   padding-right: 10px;
   border-radius: 10px;
}

.about-content{
    font-family: $base-font;
}

.about-text{
  color: $site-black-color;
  font-family:$base-font;
  font-size: $text-xs;
  line-height: 2rem;

}

.about-text-titel{
  color: $site-main-color;
  font-family:$title-font;
  font-weight: bold;
  font-size: $text-m;
  padding: 1rem 0; 
}

// .about-text-subtitel{
//   color: $site-main-color;
//   font-family:$base-font;
//   font-size: $text-sm;
//   font-style:italic;
//   padding: 1rem 2.5rem;
// }

.about-img-row{
  display: flex;
  justify-content: center;
  gap: 4rem;
}

.about-img-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.proverk-img{
 width: 10rem;
 margin: 1rem 0 2rem 0;
}

.web-about-img {
  width: 12rem;
}

.about-container-web{
  background-color: $element-color !important;
}

.home-about-content-row {
  justify-content: center;
  }

.about-imagetext{
  display: flex;
  flex-direction: column;
  color: $site-main-color;
  font-family:$title-font;
  font-size: $text-sm;
  text-align: center;
  font-weight: bold;
}
.about-subtitle{
  font-size: $text-s;
  text-align: center;
  font-weight: normal;
}

@media only screen and (max-width: 575px) { 

#header-img{
  display: none;
}

.about-text-titel{
  text-align: center;
}
.about-text{
  font-size: $text-sm;
  text-align: center;
}

}