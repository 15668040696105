$row-height: 40px;
$row-text-padding-top: 8px;


.main-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.title-row{
  display: grid;
  grid-template-columns: 2fr 2fr;
  align-items: center;
  gap: 5rem;

  .title-btn-group{
    display: flex;
    align-items: center;
    gap: 25px;
  }

  .filter-search-group{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 25px;
    height: 2rem;

    .filter{
      display: flex;
      align-items: center;
      gap: 25px;
    }
  }
}
.main-table{
  display: flex;
  flex-direction: column;
  height: 75vh;

  .main-table-grid{
    display: grid;
    grid-template-columns: 
    1fr 0.7fr 1fr 1fr 3fr 0.5fr 0.7fr repeat(3,0.5fr) 1fr 1.5fr 0.3fr;
    text-align: center;
  }

  .main-table-header-row{
    align-items: center;
    background-color: #C2D7E8;
    height: $row-height;
    font-family: $report-font;
    color: $hover-color;
    font-size: 14px;
    font-weight: bold;
    cursor:default;
    padding-right: 1rem;
    min-height: 2.8rem;
  }
  .main-table-data-container{
    overflow-y:scroll;
  }

  .main-table-data-row{
    align-items: center;
    background-color: #C2D7E8;
    height: $row-height;
    font-family: $report-font;
    color: $hover-color;
    font-size: 14px;
    cursor:pointer;
    justify-content: center;
  }

  .text-trim{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .main-table-data-row:hover{
    background-color: $hover-color !important;
    color: $element-color;
  }

  .project-picture{
    display: flex;
    overflow: hidden;
    height: 100%;
    justify-content: center;
    align-items: center;

    img{
      width: 100% !important;
      height: $row-height + 20px !important;
    }
  }

  .status-cell{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .status-icon{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border-color: $site-main-color;
    cursor:pointer;
    background-color: $element-color;
}

  .status-icon-active {
    background-color: #26AF66;
  }

  .status-icon-closed {
    background-color: #F3C93A;
  }

  .status-icon-deleted {
    background-color: #DD392C;
  }

  .white-row{
    background-color: #ffffff !important;
  }
  .grey-row{
    background-color: #d1d8e1 !important;
  }
}


.bk-projects-container {
  flex: auto;
  text-align:center;
  width:100%;
  padding-bottom:100px;
  z-index:100;
}

.bk-row-header-container {
  display: flex;
}

.project-row-header{
  color: $site-main-color;
   font-size: $text-m;
   text-align: left;
  padding-bottom: 10px;
}

.bk-project-search {

  margin-top: 12px;
  
  input {
    width:100% !important;
  }
}

.bk-project-row-buttons {

  padding-top: 8px;
}

.bk-row-header-text{
  padding-top: $row-text-padding-top;
  font-family: $report-font;
  color: $hover-color;
}
.bk-row-header-text-padding{
  padding-right: 20px !important
}



.bk-projectRows-container {
  height: 73vh;
  overflow-y:scroll;
  }

.bk-row-orange {
  display: flex;
  background-color: #e1c4ad;
  width: 100%;
  height: $row-height;
  font-size: 14px;
  font-weight: bold;
  padding-left:20px;
  padding-right:0px;
}

.bk-row-blue {
  display: flex;
  background-color: #C2D7E8;
  width: 100%;
  height: $row-height;
  font-size: 14px;
  font-weight: bold;
  border-top: 1px solid #CBCBCB;
  padding-left:20px;
  padding-right:0px;
}

#bk-small-project-row {
  padding-left: 15px;
  background-color: $site-main-color;
  color: $element-color;
  border-radius: 2px;
}

.bk-row-contact {
  display: flex;
  background-color: #C2D7E8;
  width: 100%;
  height: $row-height;
  font-size: 14px;
  font-weight: bold;
}

.bk-row-grey {
  display: flex;
  background-color: #d1d8e1;
  width: 100%;
  border-top: 1px solid #CBCBCB;
  padding-left:20px;
  padding-right:0px;
}

.bk-row-white {
  display: flex;
  background-color: white;
  width: 100%;
  border-top: 1px solid #CBCBCB;
  padding-left:20px;
  padding-right:0px;
}

.bk-project-row-flex-min {
  flex: 0.5;
}

.bk-project-row-flex-075 {
  flex: 0.75;
}


.bk-project-row-flex-1 {
  flex: 1;
}

.bk-project-row-flex-1-5{
  flex: 1.5;
}

.bk-project-row-flex-2 {
  flex: 2;
}

.bk-project-row-flex-3 {
  flex: 3;
}
.bk-project-row-flex-4 {
  flex: 4;
}

.bk-project-row-flex-5 {
  flex: 5;
}
.bk-project-row-flex-6 {
  flex: 6;
}

// .bk-project-header {
//   font-weight: bold;
//   font-size: 18px;
// }

.bk-project-row {
  color: #31363A;
  font-size: 15px;
  height: $row-height;
  font-weight: 500;
  cursor:pointer;

  .bk-project-text-trim {
    white-space: nowrap;
    overflow: hidden;
  }
}

.bk-project-row:hover {
  background-color: $hover-color !important;
  color:$element-color !important;
}

.bk-project-row-text {
  padding-top: $row-text-padding-top;
  font-size: $text-xxs;
}

.bk-project-row-format {
  margin-left: 10px;
  white-space:nowrap;
  overflow:hidden
}



.bk-project-button-margin-right {
  margin-right: 20px;
}

.bk-project-image {
  width: 100%;
  height:100%;
  object-fit: cover;
  overflow: hidden;
}

.bk-project-image-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;  
  left: 0;
  width: 100%;
  height:100%;
  z-index: 2;
}

.bk-project-image-background img {
  width: 100%;
  height:100%;
  //filter: blur(8px);
  object-fit: cover;
  overflow: hidden;
  //height: 22rem;
  opacity: .8;

}

.bk-project-icon {
  float: right;
  width: 40px;
  padding-right: 10px;
  cursor: pointer;
}

.bk-project-header {
  width: 100%;
  height: 300px; /*57vh*/
  //background: linear-gradient(90deg, rgba(106,157,158,1) 0, rgba(135,176,177,1) 50%, rgba(212,228,228,1) 100%);
  background: transparent;
  //padding: 10px;
}


.bk-project-header-tiles {
  width: 100%;
  height: 230px;
  display: flex;
}


.bk-project-header-tile-image-container {
  flex: 12;
  height: 230px;
  background-color: white;
  // margin-left: 10px;
  //margin-right: 20px;
  position:relative;
  display: flex;
  align-items: center;
  cursor:pointer;
}

.bk-project-image-div {
  position: absolute;
  width: 100%;
  height:100%;
  z-index: 100;
}

.bk-project-header-tile {
  flex: 10;
  height: 230px;
  background-color: white;
  margin-left: 20px;
  //margin-right: 10px;
  position: relative;
  top: 0;
  left: 0;
  text-align: left;
  overflow:hidden;
  white-space: nowrap;
}

.transparent {
  background-color: transparent;
}



.bk-project-header-tile-top {
  color: $site-main-color;
  text-transform: uppercase;
  font-size: 0.8vw;
  font-weight: bold;
  margin-top: 0.3vw;
  margin-left: 1vw;
  font-family: $base-font;
  font-size: $text-s;
  //margin-left: 1vw;
  margin-bottom: 0.5vw;
}

.bk-project-header-tile-text-frame {
  text-align: left;
  margin-bottom: 0.2vw;
  display:flex;
  margin-left: 1vw;
  margin-right: 0.5vw;

  .notes {
   white-space: pre-line;
}
}

.bk-project-header-tile-text-header {
  color: #334652;
  font-size: 0.7vw;
  font-weight: bold;
//  margin-left: 1vw;
  flex:4;
}

.bk-project-header-tile-text-value {
  color: #334652;
  font-size: $text-xxs;
  // font-weight: bold;
  //margin-left: 1vw;
  flex:8;
  overflow:hidden;
  white-space: nowrap;
  //margin-right:1vh;
  //padding-right:1vh;
  span {
    overflow: hidden;
    white-space: nowrap;
    display: block;  }
}


.bk-project-header-tile-text-box {
   color: #334652;
   font-size: $text-xxs;
   white-space: initial;
   flex:12;
   display:flex;
   max-height:160px;
   overflow:hidden;
   div {
     flex:1;
   }

 }
 


.bk-project-header-tile-text {
  color: #334652;
  font-size: $text-xxs;
  // font-weight: bold;
  //margin-left: 1vw;
  flex:12;
  display:flex;
  div {
    flex:1;
  }
}

.bk-project-header-tile-property-container{
  font-size: $text-xxs;
  //margin-left: 1vw;
  color: #334652;
}

.bk-project-header-tile-property {
  width:5vw;
  float:left;
}


.bk-project-header-tile-bottom {
  width: 100%;
  height: 3vw;
  position: absolute;
  bottom: 0;
  padding-left: 1vw;
  display: flex;
}

.bk-project-header-tile-bottom-date {
  flex: 4;
  color: #334652;
  font-size: 0.7vw;
  font-weight: bold;
  padding-bottom: 0.2vw;
  margin-top: 1.4vw;
}


.bk-project-header-tile-bottom-text {
  flex: 4;
  color: #334652;
  font-size: 0.7vw;
  font-weight: bold;
  align-self: flex-end;
  padding-bottom: 0.5vw;
}

.bk-project-header-tile-bottom-color {
  flex: 4;
  align-self: flex-end;
  padding-bottom: 0.2vw;
}

.bk-project-header-tile-bottom-edit {
  flex: 2;
  align-self: flex-end;
  padding-bottom: 0.2vw;
}

.bk-project-image-header-tile-bottom-edit {
  position: absolute;
  //background-color: white;
  bottom: 0;
  z-index: 500;
  right:0;
  padding-bottom:0.2vw;
}

.bk-triangle-bottomright {
  position: absolute;
  bottom: 0;
  right:0;
  width: 0;
  height: 0;
  border-bottom: 76px solid white;
  border-left: 76px solid transparent;
  opacity: 0.4;
  z-index: 500;
}


.bk-header-icon-edit {
  float: right;
  width: 1.8vw;
  padding-right: 0.2vw;
  cursor:pointer;
}


// .bk-header-dot {
//   height: 1vw;
//   width: 1vw;
//   background-color: red;
//   border-radius: 50%;
//   display: inline-block;
// }





.bk-project-row-header {
  background-color: #6A9C9D;
  color: white;
  width: 100%;
  height: 2vw;
  padding-top: 0.5vw;
  padding-left: 3vw;
  font-size: 0.9vw;
  font-weight: bold;
  margin-top: 0.6vw;
  margin-bottom: 0.3vw;
}

.bk-project-row-margin {
  height: 1.4vw;
  //border-top: 1px solid #CBCBCB;
}

.bk-project-row-grey {
  background-color: #D1E0E1;
  color: white;
  width: 100%;
  height: 2vw;
  padding-top: 0.5vw;
  padding-left: 3vw;
  font-size: 0.9vw;
  font-weight: bold;
  border-top: 1px solid #CBCBCB;
}

.bk-project-row-white {
  background-color: white;
  color: white;
  width: 100%;
  height: 2vw;
  padding-top: 0.5vw;
  padding-left: 3vw;
  font-size: 0.9vw;
  font-weight: bold;
  border-top: 1px solid #CBCBCB;
}

.bk-project-row-blue {
  background-color: #C2D7E8;
  color: white;
  width: 100%;
  height: 2vw;
  padding-top: 0.5vw;
  padding-left: 3vw;
  font-size: 0.9vw;
  font-weight: bold;
  border-top: 1px solid #CBCBCB;
}

.bk-project-row-green {
  background-color: #A6CBC9;
  color: white;
  width: 100%;
  height: 2vw;
  padding-top: 0.5vw;
  padding-left: 3vw;
  font-size: 0.9vw;
  font-weight: bold;
  border-top: 1px solid #CBCBCB;
}

.bk-project-row-align-left {
  text-align: left;
}
.bk-project-row-align-right {
  text-align: right;
}

.bk-project-row-align-center {
  text-align: center;
}

.add-margin{
  margin-top:0.6vw;
}

.project-dialog-field-row{
  // display:grid;
  // grid-template-columns: 1fr repeat(5,1fr) 0.9fr;
  margin-bottom: 1rem;
}


.project-dialog-edit-wrapper{
  display:flex;
  //min-width:00px;
}

.project-dialog-edit-frame {
  flex:1;
  display:block;
  padding: 0 20px ;

  div {
    margin-right:10px;
  }
}

.bk-projects-no-image {
  width: 100%;
  height: 100%;
  /*background: 
         linear-gradient(to top left,
             rgba(170,170,170,0) 0%,
             rgba(170,170,170,0) calc(50% - 0.8px),
             rgba(170,170,170,1) 50%,
             rgba(170,170,170,0) calc(50% + 0.8px),
             rgba(170,170,170,0) 100%),
         linear-gradient(to top right,
             rgba(170,170,170,0) 0%,
             rgba(170,170,170,0) calc(50% - 0.8px),
             rgba(170,170,170,1) 50%,
             rgba(170,170,170,0) calc(50% + 0.8px),
             rgba(170,170,170,0) 100%);*/
  padding-top:40%;
  font-family: $base-font;
  font-size: $text-m;
  color: $site-main-color;
  font-weight: bold;
}

.bk-project-header-button-row {
  width: 100%;
  height: 4vw;
  display: flex;
  padding-top:10px;
}

.bk-project-buttons {
  flex:1;
  display:flex;
  padding-top:8px;
}

.bk-project-header-button {
  background-color: #D1E0E1;
  color: #334652;
  font-size: 0.8vw;
  font-weight: bold;
  margin-top: 0.5vw;
  //margin-left: 0.5vw;
  margin-right: 1vw;
  padding-left: 1.3vw;
  padding-right: 1.3vw;
  padding-top: 0.4vw;
  height: 2vw;
  min-width:8vw;
  cursor:pointer;
  border:1px solid white;
}


.bk-project-status {
  flex:5;
  display:flex;
  flex-direction: row-reverse;
  margin-right:2vw;
  padding-top:8px;
  // display:flex;
  // float:right;
  // //border:1px solid green;
  // margin-right:3vw;
  // padding-top:0.5vw;
  // cursor:pointer;
}

.bk-project-status-text {
  flex:5;
  margin-right:1vw;
  color: #334652;
  font-size: 0.8vw;
  font-weight: bold;
  margin-top:0.35vw;
}

.bk-project-printer-icon {
 margin-top: 7px;
 margin-right: 10px;
}


.bk-header-icon-printer {
  height: 45px;
  width: 45px;
  cursor:pointer;
  margin-bottom: 7px;
}

.bk-calculation-project-link{
  
}

.bk-header-icon-printer-report{
  height: 45px;
  width: 45px;
  margin-bottom: 8px;
  cursor:pointer;
}

.aligned{
justify-self: end !important;
justify-content: end !important;
justify-items:  end !important;
text-align: end !important;
}


.bk-header-project-btn{
  margin-top: 21px;
}

.bk-project-status-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-top: 8px;
  border-color: $site-main-color;
  cursor:pointer;
  background-color: $element-color;
}

.bk-project-icon-cell{
  margin-left: 30px;
  margin-top: 5px;
}

.bk-project-status-active {
  background-color: #26AF66;
}

.bk-project-status-closed {
  background-color: #F3C93A;
}

.bk-project-status-deleted {
  background-color: #DD392C;
}


.bk-upload-frame {
  width:100%;
  height:60%;
  margin:2vw;
  border:4px dashed rgba(106,157,158,1);
  border-radius: 10px;
  cursor: pointer;
}



.bk-upload-frame-header{
  font-size: 22px;
  font-weight: bold;
  margin-top:1vw;
  color: rgba(106,157,158,1);
}




.bk-project-row-image{
  padding-top:1px;
  
  div {

    img {
    //  width:auto !important;
    //  height:36px !important;
    }
  }
}




.bk-upload-frame-2 {
  width:100%;
  height:100%;
  background: 
         linear-gradient(to top left,
             rgba(170,170,170,0) 0%,
             rgba(170,170,170,0) calc(50% - 0.8px),
             rgba(170,170,170,1) 50%,
             rgba(170,170,170,0) calc(50% + 0.8px),
             rgba(170,170,170,0) 100%),
         linear-gradient(to top right,
             rgba(170,170,170,0) 0%,
             rgba(170,170,170,0) calc(50% - 0.8px),
             rgba(170,170,170,1) 50%,
             rgba(170,170,170,0) calc(50% + 0.8px),
             rgba(170,170,170,0) 100%);
}

.bk-border-top {
  border-top:1px solid black;
}

.new-service-container{
  display:flex;
  min-height:50vh;
  max-height:50vh;

  .new-service-column {
    flex:4;
    background-color: $element-color;
    margin:10px;
    padding:10px;
    overflow-y: scroll;
    font-family: $title-font;
    color: $site-main-color;
    font-size: $text-sm;
    
    &::-webkit-scrollbar {
      
    }
  }


  .new-service-button {
    //max-width: 12vw;      
    background-color: $site-light-color;
    color: $site-black-color;
    border-radius: $site-border-radius;
    font-size: 0.8vw;
    font-weight: bold;
    margin-top: 0.5vw;
    margin-right: 0;
    padding-left: 0.8vw;
    padding-right: 0.8vw;
    padding-top: 0.4vw;
    height: 2vw;
    min-width: 8vw;
    cursor:pointer;

    &:hover {
      background-color:$hover-color;
      color: $element-color;
    }
  }
  .new-service-button-selected {
    background-color:$hover-color;
    color: $element-color;
  }


  .selectEmpty {
    margin-top: 2px;
  }

}

.bk-report-title{
  display: flex;
  flex-direction: column;
}

.bk-project-owner-title{
  color: #be5310;
  font-family: $title-font;
  font-size: $text-sm;
}
.bk-project-status-title{
  color: $site-main-color;
  font-family: $title-font;
  font-size: $text-m;
  margin-top: 5px;
}

.bk-project-status-button{
  background-color: $site-green-color;
  padding-top: 0.2px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  cursor:pointer;

  &:hover{
    background-color: $hover-color;
  }
}


.projects-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.project-note-dialog {
   min-width:300px;
}