.timeline-module-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: auto 3fr;
    margin: 2rem 5rem 2rem 5rem;
    gap: 1.5rem;

}

.vertical-timeline-grid {
    margin: 0;
    display: grid;
    grid-template-columns: 0.3fr 0.1fr;
}

.timeline-year {
    display: flex;
    justify-content: center;
    font-weight: bold;
    margin-right: 2rem;
    min-height: 2rem;
    font-family: $base-font;
    line-height: 50%;
}

.timeline-name{
    font-weight: bold;
    margin-left: 1rem;
    font-size: large;
    font-family: $base-font;
}

.timeline-year-offyear {
    font-weight: normal;
    font-size: small;
}

.timeline-line {
    position: relative;
    background-color: #000;
    width: 1px;
}

.timeline-point {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 2px;
    background-color: #000;
}

.timeline-line-bottom {
    position: relative;
    background-color: #000;
    width: 1px;
    height: 10%;
}

.timeline-point-bottom {
    top: initial;
    bottom: 0;
}

.point-bold {
    width: 40px;
    height: 3px;
    background-color: #000;
}

.events-container {
    position: relative;
}

.event {
    position: absolute;
    top: 0;
    border-left: 2px solid black;
    height: 24px * 3;
    margin-top: 24px * 2;
}

.event-name {
    color: #000;
    font-weight: bold;
    font-family: $base-font;
    font-size: small;
    text-align: end;
    line-height: 90%;
}

.event-container {
    display: flex;
}

.event-vertical-line {
    position: relative;
    width: 2px;
    margin-left: 2px;
}

.event {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.legend-list {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 1px 2px 5px #c4c3c3;
    gap: 0.2rem;
    padding: 1rem 0 1rem 0;
    margin: 2rem;
}

.legend-item-container {
    display: grid;
    grid-template-columns: auto 2fr;
    margin: 0 1rem 0 1rem;
    gap: 1rem;
    align-items: center;
}

.legend-item-color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.legend-item-name {
    color: #000;
    font-weight: bold;
    font-family: $base-font;
}